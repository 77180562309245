import React from 'react';
import styled from 'styled-components';

const StyledSkipLink = styled.div`
  a {
    position: absolute !important;
    overflow: hidden;
    clip: rect(1px,1px,1px,1px);
    width: 1px;
    height: 1px;
    word-wrap: normal;
    display: block;
    position: relative;
    z-index: 20;
    padding: 10px;
    text-align: center;
    background-color: #1a1a1a;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    color: #ffffff;
    text-decoration: underline;

    &:focus,
    &:active {
      position: relative !important;
      overflow: visible;
      clip: auto;
      width: auto;
      height: auto;
    }
  }
`;

export default function PageHeader(props) {
  return (
    <StyledSkipLink>
      <a href="#main-content">Overslaan en naar de inhoud gaan</a>
    </StyledSkipLink>
  )
}

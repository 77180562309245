import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Overview from '../Overview/Overview';
import ResultSelector from '../ResultSelector/ResultSelector';
import ResultContainer from '../ResultContainer/ResultContainer';
import { Provider } from 'react-redux';
import { store, persistor } from '../../store/Store';
import { PersistGate } from "redux-persist/lib/integration/react";
import { Loading } from "../Loading/Loading";
import { theme } from '../../styles/theme';
import { ThemeProvider } from 'styled-components';
import PageHeader from '../Header/Header';
import PageFooter from '../Footer/Footer';
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import SkipLink from "../SkipLink/SkipLink";
import StepSelector from "../StepSelector/StepSelector";
import NotFound from "../NotFound/NotFound";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <SkipLink />
          <div className="page">
            <BrowserRouter>
              <ScrollToTop />
              <PageHeader />
              <main id="main-content">
                <Routes>
                  <Route path="/" element={<Overview />} />
                  <Route path=":slug" element={<StepSelector />}>
                      <Route path="stap/:index" element={<StepSelector />} />
                  </Route>
                  <Route path="resultaat" element={<ResultContainer />} />
                  <Route path="resultaat/:id/:token" element={<ResultSelector />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </main>
              <PageFooter />
           </BrowserRouter>
          </div>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;

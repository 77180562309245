import React from 'react';
import {useSelector} from 'react-redux';
import Result from '../Result/Result';

export default function ResultContainer() {
  const results = useSelector((state) => state.results);
  const remote = useSelector((state) => state.remote);

  return <Result results={results} remote={remote}/>;
}

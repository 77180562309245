import React from 'react';
import {getStepResult} from "../Utils/ResultUtils";
import styled from "styled-components";
import { ReactComponent as IconCheck } from '../../assets/images/check.svg';
import { ReactComponent as IconCross } from '../../assets/images/cross.svg';

const StyledStepStatus = styled.div`
  display: flex;
  align-items: center;

  @keyframes step-status-pulse {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.6);
    }
  }

  .bar {
    flex-grow: 1;
    display: block;
    width: 27px;
    height: 6px;
    border-radius: 3px;
    background-color: ${props => props.theme.color.appleGreen};
    margin-left: -6px;
    margin-right: -6px;
    z-index: -3;
    &.passed,
    &.active {
      background-color: ${props => props.theme.color.green};
    }
  }

  .disc {
    display: block;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    border: 6px solid;
    color: ${props => props.theme.color.white};
    &.to-come {
      border-color: ${props => props.theme.color.appleGreen};
    }
    &.active {
      border-color: ${props => props.theme.color.green};
      position: relative;
      .glow {
        background-color: ${props => props.theme.color.green};
        opacity: 0.2;
        display: block;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        position: absolute;
        z-index: -2;
        top: -6px;
        left: -6px;
        animation: step-status-pulse 1.8s infinite alternate ease-in-out;
      }
    }
    &.decided {
      &.ok {
        background-color: ${props => props.theme.color.green};
        border-color: ${props => props.theme.color.green};
        &.to-come {
          color: ${props => props.theme.color.green};
        }
      }
      &.not-ok {
        background-color: ${props => props.theme.color.orange};
        border-color: ${props => props.theme.color.orange};
        &.to-come {
          color: ${props => props.theme.color.orange};
        }
      }
      &.to-come {
        background-color: ${props => props.theme.color.appleGreen};
        border-color: ${props => props.theme.color.appleGreen};
      }
    }

    span.cross {
      svg {
        width: 12px;
      }
    }
  }
`;

export default function StepStatus(props) {
  const stepResult = getStepResult(props.results, props.part.slug, props.step.id);

  let discBorderColor = props => props.theme.color.appleGreen;
  let discFillColor = props => 'transparent';
  let barColor = props => props.theme.color.appleGreen;
  let activeDisc = false;

  let barClasses = ['bar'];
  let discClasses = ['disc'];

  if (stepResult !== undefined) {
    discClasses.push('decided');
    if (stepResult === 'yes') {
      discClasses.push('ok');
    }
    if (stepResult === 'no') {
      discClasses.push('not-ok');
    }
  }

  if (parseInt(props.activeIndex) <= props.stepIndex + 1 && parseInt(props.activeIndex) !== props.stepIndex + 1) {
    discClasses.push('to-come');
  }
  if (parseInt(props.activeIndex) === props.stepIndex + 1) {
    barClasses.push('active');
    discClasses.push('active');
    activeDisc = true;
  }
  if (parseInt(props.activeIndex) >= props.stepIndex + 1) {
    barClasses.push('passed');
  }

  return (
    <StyledStepStatus $discBorderColor={discBorderColor} $discFillColor={discFillColor} $barColor={barColor}>
      <span className="label visually-hidden">{props.step.label}</span>
      <span className={barClasses.join(" ")} role="presentation"></span>
      <span className={discClasses.join(" ")} role="presentation">
        { activeDisc ? <span className="glow"></span> : '' }
        <span role="presentation" className={ stepResult === 'yes' ? 'icon check' : 'icon cross' }>
          { stepResult === 'yes' ? <IconCheck/> : '' }
          { stepResult === 'no' ? <IconCross/> : '' }
        </span>
      </span>
    </StyledStepStatus>
  );
}

import {config, getPartBySlug} from '../../config/config';

/*
 * Gets the result of a specific step from the given results Object.
 *
 * @param {Object} results
 *   The results Object to get the result from.
 * @param {string} partSlug
 *   The slug of the part to get the result for.
 * @param {number} stepId
 *   The step id to get the result for.
 *
 * @return {string}
 *   The result string of the specific step.
 */
export const getStepResult = (results, partSlug, stepId) => {
  const partResult = results[partSlug];
  if (partResult !== undefined) {
    const stepResult = partResult.find(step => step.id === stepId);
    if (stepResult) {
      return stepResult.value;
    }
  }

  return undefined;
};

/**
 * Checks if all parts are completed in the given results for the given config.
 *
 * @param {Object} results
 *   The results Object to check.
 *
 * @returns {boolean}
 *   true when all parts are completed, false otherwise.
 */
export const allPartsCompleted = (results) => {
  for (let i = 0; i < config.parts.length; ++i) {
    if (getPartStatus(results, config.parts[i].slug) === 'open') {
      return false;
    }
  }

  return true;
};

/**
 * Gets the status of a specific part from the given results Object.
 *
 * @param {Object} results
 *   The results Object to get the result from.
 * @param {string} partSlug
 *   The slug of the part to get the result for.
 *
 * @returns {string}
 *   The status of the specific part:
 *   - "open" when not all steps have a value.
 *   - "fail" when one or more steps have the value "no".
 *   - "pass" when all steps have the value "yes".
 */
export const getPartStatus = (results, partSlug) => {
  if (results[partSlug] === undefined) {
    return 'open';
  }

  const part = getPartBySlug(partSlug);
  if (results[partSlug].length !== part.steps.length) {
    return 'open';
  }

  const noResults = results[partSlug].find(result => result.value === 'no');
  if (noResults) {
    return 'fail';
  }

  return 'pass';
};

/**
 * Gets an array of all steps and returns the label with the value.
 *
 * @param {Object} results
 *   The results Object to get the result from.
 *
 * @returns {array}
 *   The array of all steps containing the label and the value per step.
 */
export const getResultsWithLabel = (results) => {
  let resultsWithLabel = [];

  for (let i = 0; i < config.parts.length; ++i) {
    for (let j = 0; j < config.parts[i].steps.length; ++j) {
      let step = {};
      step.label = config.parts[i].steps[j].label;
      const stepResult = results[config.parts[i].slug].find(result => result.id === config.parts[i].steps[j].id);
      step.value = stepResult.value;
      resultsWithLabel.push(step);
    }
  }

  return resultsWithLabel;
};

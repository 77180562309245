import React from 'react';
import { getDataVersion } from '../../config/config';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { allPartsCompleted } from '../Utils/ResultUtils';
import ApiUtils from "../Utils/ApiUtils";
import { ReactComponent as ArrowRight } from '../../assets/images/arrow-right.svg';
import { setRemote } from "../../actions/Actions";
import styled from "styled-components";

const mapStateToProps = state => {
  return {
    results: state.results,
    remote: state.remote,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setRemote: remote => dispatch(setRemote(remote)),
  };
};

const StyledPostResultsLink = styled.div`
  button,
  .button {
    display: table;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .error {
    margin-top: 20px;
    text-align: center;
    font-weight: 900;
    color: ${props => props.theme.color.orange};
  }
`;

class PostResultsLink extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isPosting: false,
      error: false,
    };

    this.handleLinkClick = this.handleLinkClick.bind(this);
  }

  setRemote(remote) {
    this.props.setRemote(remote);
  }

  handleLinkClick = async(event) => {
    if (this.state.isPosting) {
      return;
    }

    // If the result was already posted, do not post again.
    if (this.props.remote.id !== undefined) {
      return;
    }

    this.setState({
      isPosting: true,
      error: false,
    });

    try {
      const preparedResults = [];

      Object.keys(this.props.results).forEach((part) => {
        if (this.props.results.hasOwnProperty(part)) {
          const steps = [];
          // Add steps.
          this.props.results[part].forEach((step) => {
            steps.push({
              step_id: step.id,
              value: step.value,
            });
          });

          preparedResults.push({
            part_id: part,
            steps
          });
        }
      });

      const message = {
        meta: {
          version: getDataVersion(),
        },
        results: preparedResults,
      };

      let result = await ApiUtils.post('/api/result', message);

      this.setRemote({
        id: result.data.id,
        token: result.data.token,
      });

      if (!this.props.noErrorMessage) {
        this.setState({
          isPosting: false,
        });
      }
    }
    catch (e) {
      if (!this.props.noErrorMessage) {
        this.setState({
          isPosting: false,
          error: true,
        });
      }
    }
  }

  render() {
    if (allPartsCompleted(this.props.results)) {
      return (
        <StyledPostResultsLink>
          <Link to="/resultaat" onClick={this.handleLinkClick} className="button">
            <span className="text">{this.props.text}</span>
            <ArrowRight/>
          </Link>
          {this.state.error &&
            <div className="error">Er is een fout opgetreden. Probeer het later opnieuw.</div>
          }
        </StyledPostResultsLink>
      );
    }
    else {
      return null;
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostResultsLink);

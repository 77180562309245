import React, {Fragment} from 'react';
import { Helmet } from "react-helmet/es/Helmet";
import { BasicPage } from "../BasicPage/BasicPage";
import { GridContainer } from "../GridContainer/GridContainer";
import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <Fragment>
      <Helmet>
        <title>Pagina niet gevonden | VVN Fietscheck </title>
      </Helmet>
      <BasicPage>
        <GridContainer>
          <div className="message">
            <h1 className="h2">Pagina niet gevonden.</h1>
            <div className="text">
              <p>De opgevraagde pagina is niet gevonden. <Link to="/">Ga naar de startpagina.</Link></p>
            </div>
          </div>
        </GridContainer>
      </BasicPage>
    </Fragment>
  );
}

import React from 'react';
import { connect } from 'react-redux';
import { deleteResults } from '../../actions/Actions';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowRight } from '../../assets/images/arrow-right.svg';

const mapDispatchToProps = dispatch => {
  return {
    deleteResults: () => dispatch(deleteResults()),
  };
};

class ResetResultLink extends React.Component {
  constructor(props) {
    super(props);

    this.linkClickHandler = this.linkClickHandler.bind(this);
  }

  linkClickHandler() {
    this.props.deleteResults();
  }

  render() {
    let text = 'Nieuwe fietscheck doen';
    if (this.props.text !== undefined) {
      text = this.props.text;
    }

    return (
      <Link className="button" to="/" onClick={this.linkClickHandler}>
        <span className="text">{text}</span>
        <ArrowRight/>
      </Link>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps
)(ResetResultLink);

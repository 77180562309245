
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Breakpoint } from '../../styles/base/variables/layout';
import { Helmet } from 'react-helmet';
import { allPartsCompleted, getResultsWithLabel } from '../Utils/ResultUtils';
import { Navigate } from 'react-router-dom';
import ResetResultLink from '../ResetResultLink/ResetResultLink';
import SendResultLinks from '../SendResultLinks/SendResultLinks';
import StatusVisual from '../StatusVisual/StatusVisual';
import OkImage from '../../assets/images/fiets-ok.gif';
import { GridContainer } from "../GridContainer/GridContainer";
import { ReactComponent as IconCheck } from '../../assets/images/check.svg';
import { ReactComponent as IconCross } from '../../assets/images/cross.svg';
import PostResultsLink from "../PostResultsLink/PostResultsLink";
import { ButtonOuterGreen } from "../ButtonOuter/ButtonOuter";

const ResultWrapper = styled.div`
  h1 {
    color: ${props => props.theme.color.green};
  }

  h2 {
    color: ${props => props.theme.color.green};
    font-weight: normal;
  }

  h3 {
    color: ${props => props.theme.color.green};
    font-size: 100%;
    margin-bottom: 20px;
  }

  .visual {
    margin-top: 25px;
  }

  .textual {
    margin-top: 25px;
  }

  .to-do {
    margin-top: 25px;
  }

  ul.results-list {
    font-weight: 900;
    li {
      display: flex;

      + li {
        margin-top: 20px;
      }

      .disc {
        position: relative;
        top: 1px;
        margin-right: 10px;
      }
    }
  }

  .disc {
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.ok {
      background-color: ${props => props.theme.color.green};
    }

    &.error {
      background-color: ${props => props.theme.color.orange};
    }

    svg {
    max-width: 10px;
      fill: ${props => props.theme.color.white};
    }
  }
`;

const ResultHeaderOuter = styled.div`
  ${Breakpoint.greaterThan('large')`
    background-color: ${props => props.theme.color.appleGreenDimmed};
  `}
`;

const ResultHeader = styled.div`
  background-color: ${props => props.theme.color.appleGreen};
  overflow: hidden;

  ${Breakpoint.lessThan('large')`
    width: 1070px;
    padding-top: 35px;
    margin-top: -35px;
    margin-left: calc(50% - 535px);
    padding-left: calc(535px - 50%);
    padding-right: calc(535px - 50%);
    border-radius: 0 0 535px 535px;
    box-shadow: 65px 0px 0px ${props => props.theme.color.appleGreenDimmed};
  `}

  ${Breakpoint.greaterThan('large')`
    padding-left: 10%;
    padding-right: 10%;
    margin-left: -10%;
    width: 120%;
    border-radius: 0 0 40% 0;
  `}
`;

const ResultHeaderInner = styled.div`
  padding-top: 100px;
  padding-bottom: 120px;
  ${Breakpoint.greaterThan('small')`
    padding-top: 150px;
  `}
  ${Breakpoint.greaterThan('large')`
    padding-bottom: 50px;
  `}
`;

const ResultHeaderContent = styled.div`
  margin-left: auto;
  margin-right: auto;
  ${Breakpoint.greaterThan('medium')`
    width: ${8/12*100}%;
  `}
  ${Breakpoint.greaterThan('large')`
    width: ${4/12*100}%;
  `}
`;

const Share = styled.div`
  font-weight: 900;
  margin-top: 30px;

  ul {
    margin-top: 20px;
  }
`;

const ResultContent = styled.div`
  padding-top: 40px;

  button,
  .button {
    display: table;
    margin: 30px auto;
    ${Breakpoint.greaterThan('small')`
      margin-top: 80px;
    `}
  }
  h2 {
    font-weight: 900;
  }
`;

const ResultContentInner = styled.div`
  margin-left: auto;
  margin-right: auto;
  ${Breakpoint.greaterThan('medium')`
    width: ${8/12*100}%;
  `}
  ${Breakpoint.greaterThan('large')`
    width: ${4/12*100}%;
  `}
`;

const StyledMessage = styled.div`
  margin-top: 40px;
  padding: 30px;
  background-color: ${props => props.theme.color.white};

  button,
  .button {
    margin-top: 20px;
  }
`

export default function Result({ results, remote }) {
  if (allPartsCompleted(results)) {
    const resultsWithLabel = getResultsWithLabel(results);
    const noResults = resultsWithLabel.filter(result => result.value === 'no');
    const yesResultCount = resultsWithLabel.length - noResults.length;
    let resultLink = '';
    if (remote !== undefined && remote.id !== undefined) {
      resultLink = window.location.href + '/' + remote.id + '/' + remote.token;
    }

    return (
      <ResultWrapper>
        <Helmet>
          <title>Jouw fietscheck score | VVN Fietscheck </title>
        </Helmet>
        <ResultHeaderOuter>
          <ResultHeader>
            <ResultHeaderInner>
              <GridContainer>
                <ResultHeaderContent>
                  <h1 className="h2">Jouw fietscheck score</h1>
                  <h2>{yesResultCount} van de {resultsWithLabel.length} goed!</h2>
                  {yesResultCount === resultsWithLabel.length && (
                    <div>
                      <div className="visual">
                        <img src={OkImage} alt="Je fiets is goedgekeurd" />
                        <br />
                      </div>
                      <Share>
                        Geweldig, jouw fiets is veilig! Laat zien dat jouw fiets OK is en deel de VVN Fietscheck.
                        <SendResultLinks link="https://fietscheck.vvn.nl" text="Mijn fiets is OK! Doe ook de VVN fietscheck." />
                      </Share>
                    </div>
                  )}
                  {yesResultCount !== resultsWithLabel.length && (
                    <Fragment>
                      <div className="visual">
                        <StatusVisual results={results} />
                      </div>
                      <div className="textual">
                        Je bent er bijna! Om veilig op de weg te kunnen moet je fiets
                        voldoen aan alle punten.
                      </div>
                      <div className="to-do">
                        {noResults.length > 1 ? (
                          <h3>Check de volgende punten:</h3>
                        ) : (
                          <h3>Check het volgende punt:</h3>
                        )}
                        <ul className="results-list">
                          {noResults.map(result => (
                            <li key={result.label}>
                              <span className={ result.value === 'yes' ? 'disc ok' : 'disc error' } role="presentation">
                                <span className="icon">
                                  { result.value === 'yes' ? <IconCheck/> : '' }
                                  { result.value === 'no' ? <IconCross/> : '' }
                                </span>
                              </span>
                              <span className="text">{result.label}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                      {resultLink !== '' && (
                        <Share>
                          Deel je checklist met je ouders of fietsenmaker voor een veilige fiets.
                          <SendResultLinks link={resultLink} text="Ik heb de VVN Fietscheck gedaan. Bekijk mijn score en check ook je fiets." />
                        </Share>
                      )}
                      {resultLink === '' && remote !== undefined && (
                        <StyledMessage>
                          Er was een probleem met het opslaan van je resultaat, waardoor je deze nog niet kunt delen. Ben je wel verbonden met het internet?
                          <ButtonOuterGreen>
                            <PostResultsLink text="Probeer opnieuw" />
                          </ButtonOuterGreen>
                        </StyledMessage>
                      )}
                    </Fragment>
                  )}
                </ResultHeaderContent>
              </GridContainer>
            </ResultHeaderInner>
          </ResultHeader>
        </ResultHeaderOuter>
        <ResultContent>
          <GridContainer>
            <ResultContentInner>
              <h3>Resultaten</h3>
              <ul className="results-list">
                {resultsWithLabel.map(result => (
                  <li key={result.label} >
                    <span className={ result.value === 'yes' ? 'disc ok' : 'disc error' } role="presentation">
                      <span className="icon">
                        { result.value === 'yes' ? <IconCheck/> : '' }
                        { result.value === 'no' ? <IconCross/> : '' }
                      </span>
                    </span>
                    <span className="text">{result.label}</span>
                  </li>
                ))}
              </ul>
              <ButtonOuterGreen>
                <ResetResultLink />
              </ButtonOuterGreen>
            </ResultContentInner>
          </GridContainer>
        </ResultContent>
      </ResultWrapper>
    );
  } else {
    return <Navigate to="/" />;
  }
}

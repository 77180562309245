
import React from 'react';
import styled from 'styled-components';
import { GridContainer } from "../GridContainer/GridContainer";
import { Breakpoint } from '../../styles/base/variables/layout';
import { fontFamily } from '../../styles/base/variables/fontFamily';
import { ReactComponent as LifeLine } from '../../assets/images/life-line.svg';
import { ReactComponent as VvnLogo } from '../../assets/images/logo.svg';

const StyledFooter = styled.footer`
  padding: 30px 0;

  .footer-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: -10px -20px;
    ${Breakpoint.greaterThan('large')`
      justify-content: space-between;
    `}

    > * {
      margin: 10px 20px;
    }
  }

  .site-slogan {
    font-family: ${props => fontFamily.poppins};
    color: ${props => props.theme.color.orange};
    font-weight: 700;
  }

  .life-line {
    color: ${props => props.theme.color.lightGrey};
    border-bottom: 1px solid currentColor;
    margin-bottom: 30px;
    ${Breakpoint.greaterThan('small')`
      border-width: 2px;
    `}

    .life-line-inner {
      width: 456px;
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: -1px;
      ${Breakpoint.greaterThan('small')`
        margin-bottom: -2px;
      `}
    }

    .visual {
      position: relative;
      height: 0;
      padding-bottom: ${47/456*100}%;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .active-phase {
        fill: ${props => props.theme.color.green};
      }
    }
  }

  nav {
    ${Breakpoint.lessThan('small')`
      order: -1;
    `}
    ul {
      flex-wrap: wrap;
      ${Breakpoint.lessThan('small')`
        justify-content: center;
      `}
    }
  }
`;

export default function PageFooter(props) {
  return (
    <StyledFooter>
      <div className="life-line">
        <GridContainer>
          <div className="life-line-inner">
            <div className="visual">
              <LifeLine/>
            </div>
          </div>
        </GridContainer>
      </div>
      <GridContainer>
        <div className="footer-inner">
          <div className="site-slogan">Iedereen veilig over straat</div>
          <VvnLogo/>
          <nav className="footer-menu">
            <ul>
              <li>
                <a href="https://vvn.nl/disclaimer">Disclaimer</a>
              </li>
              <li>
                <a href="https://vvn.nl/privacyverklaring">Privacyverklaring</a>
              </li>
            </ul>
          </nav>
        </div>
      </GridContainer>
    </StyledFooter>
  );
}

import { generateMedia } from 'styled-media-query';

const pagePadding = '5';
const gridContainerMax = '1170';
const bpGridContainerMax = gridContainerMax * 100/(100 - (pagePadding * 2));

export const width = {
  gridMax: gridContainerMax,
  pagePadding: pagePadding,
};

export const Breakpoint = generateMedia({
  small: '600px',
  medium: '768px',
  large: '980px',
  gridMax: bpGridContainerMax + 'px',
});

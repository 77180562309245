// Defining colors.
const black = '#000000';
const white = '#FFFFFF';
const green = '#00853e';
const appleGreen = '#e5f4ec';
const appleGreenDimmed = '#f3f8f5';
const appleGreenDimmedBlended = '#c3dbc9';
const orange = '#f66904';
const blue = '#009cca';
const lightGrey = '#dadada';
const darkGrey = '#404040';

// Exporting colors.
export const color = {
  black,
  white,
  green,
  appleGreen,
  appleGreenDimmed,
  appleGreenDimmedBlended,
  orange,
  blue,
  lightGrey,
  darkGrey,
};

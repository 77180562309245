export const config = {
  data_version: 1,
  parts: [
    {
      name: 'Het stuur',
      slug: 'stuur',
      steps: [
        {
          id: 1,
          label: 'Stuur zit vast',
          question: 'Zit je stuur goed vast?',
          explanation:
            'Check of het stuur goed vastzit door je voorwiel tussen je benen te klemmen en aan je stuur te draaien.',
          yes:
            'Nog een tip: Fiets MONO en houd je aandacht bij het verkeer. Zo voorkom je ongelukken én een boete.',
          no:
            'Je stuur moet goed vastzitten, omdat je anders moeilijk bochten kunt maken of gaat slingeren. Zet ’m vast en check ’m daarna nog een keer.',
          fact:
            '... je sinds 1 juli 2019 niet meer je telefoon in je handen mag houden als je fietst? Houd dus je handen goed aan het stuur en voorkom een hoge boete.',
          viewBox: '245 -20 210 210',
        },
        {
          id: 2,
          label: 'Bel werkt',
          question: 'Is je bel goed te horen?',
          explanation:
            'Check je bel om te horen of hij werkt en of het geluid luid genoeg is voor anderen om te horen.',
          yes:
            'Nog een tip: Gebruik de bel niet onnodig. De bel is bedoeld om iemand te waarschuwen op het moment dat er gevaar dreigt.',
          no:
            'Je bel moet goed werken om anderen te kunnen waarschuwen. Draai ’m vaster of zet een nieuwe bel op je fiets. Check ’m daarna nog een keer.',
          viewBox: '245 -20 210 210',
        },
        {
          id: 3,
          label: 'Handvatten zitten vast',
          question: 'Zitten de handvatten goed vast en zijn ze heel?',
          explanation:
            'Check of je de handvatten niet kunt bewegen en of ze niet van je stuur afglijden. ',
          yes:
            'Nog een tip: Houd je handen aan het stuur. Zo voorkom je ongelukken.',
          no:
            'Je handvatten moeten goed vastzitten en heel zijn, omdat je lelijk kunt vallen als ze losschieten tijdens het fietsen. Lijm je handvatten vast met geschikte lijm of laat nieuwe handvatten op je fiets zetten. Check ze daarna nog een keer.',
          viewBox: '245 -20 210 210',
        },
        {
          id: 4,
          label: 'Remmen werken',
          question:
            'Werken je remmen goed?',
          explanation:
            'Bij een terugtraprem: als je terugtrapt moeten de remmen direct gaan werken en moet je op tijd stil kunnen staan. Bij handremmen geldt: beide wielen moeten goed geremd kunnen worden. Ga naast je fiets staan en houd je stuur vast. Knijp je remmen in en probeer je fiets naar voren te duwen. Gaat dit moeilijk, dan werken je remmen goed.',
          yes:
            'Nog een tip: Rem voorzichtig als het regent. Door het natte wegdek kunnen je banden slippen. Trap of knijp de remmen daarom niet keihard in.',
          no:
            'Je remmen moeten goed werken om snel stil te kunnen staan als het nodig is. Rem je alleen met je voorrem, dan kun je over de kop vliegen. Zorg er dus voor dat beide remmen goed werken. Vervang je remblokjes en/of remkabels of laat je remmen repareren en check ze daarna nog een keer.',
          viewBox: '245 -20 210 210',
        },
      ],
    },
    {
      name: 'De koplamp',
      slug: 'koplamp',
      steps: [
        {
          id: 1,
          label: 'Koplamp werkt',
          question:
            'Werkt je koplamp, straalt hij recht naar voren en geeft hij wit of geel licht?',
          explanation:
            'Zet je koplamp aan en kijk of hij het goed doet. Werkt je koplamp met een dynamo? Vraag dan iemand om je voorwiel iets op te tillen en draai aan het wiel om te kijken of je koplamp het goed doet. De fietsverlichting moet recht naar voren schijnen. Deze mag andere weggebruikers niet verblinden en mag ook niet knipperen.',
          yes:
            'Nog een tip: Je mag in noodgevallen een los lampje gebruiken in plaats van vaste fietsverlichting. Zorg er wel voor dat je het op een logische plek vastmaakt en dat het niet knippert. Zorg er ook voor dat je altijd reservebatterijen in huis hebt.',
          no:
            'Bij het verkeersexamen is fietsverlichting niet verplicht, omdat het overdag is. Maar ook bij een flinke regenbui kan het best donker worden. Zorg er daarom voor dat je fietsverlichting altijd goed werkt, zodat ander verkeer jou op tijd ziet. Laat je koplamp repareren of gebruik tijdelijk een los lampje. Check je verlichting daarna nog een keer.',
          viewBox: '350 100 100 100',
        },
      ],
    },
    {
      name: 'De wielen',
      slug: 'wielen',
      steps: [
        {
          id: 1,
          label: 'Spaken zitten vast',
          question:
            'Zitten de spaken in beide wielen goed vast en ontbreken er geen?',
          explanation:
            'Check of alle spaken in de wielen zitten en of ze goed vastzitten.',
          yes:
            'Nog een tip: Maak geen lichtslang vast in je spaken. Zo’n slang van LED-lampjes is wel opvallend, maar mag officieel niet.',
          no:
            'Alle spaken moeten aanwezig zijn en goed vastzitten. Zij zorgen ervoor dat de wielen meer gewicht kunnen dragen. Laat je spaken repareren en check ze daarna nog een keer.',
          viewBox: '320 125 300 300',
        },
        {
          id: 2,
          label: 'Zijreflectie in de wielen',
          question:
            'Hebben de banden cirkelvormige reflectie of zitten er witte of gele reflectoren in de wielen? ',
          explanation:
            'Check of er een reflecterend lint in je banden zit of dat er minimaal één witte of gele reflector in elk wiel zit.',
          yes:
            'Nog een tip: Er zijn allerlei soorten reflectoren die je kunt vastmaken in je spaken. Ze zijn dus niet alleen nuttig, je pimpt er ook je fiets mee op.',
          no:
            'Je fiets moet zijreflectie hebben om in het donker gezien te worden van opzij. Zet reflectoren in je wielen en check ze daarna nog een keer.',
          viewBox: '320 125 300 300',
        },
        {
          id: 3,
          label: 'Banden goed opgepompt, met profiel',
          question:
            'Zijn je banden goed opgepompt en hebben ze voldoende profiel?',
          explanation:
            'Voel aan de banden of ze hard genoeg zijn. Kijk daarna of er genoeg profiel op zit. Ze mogen niet (bijna) glad zijn. Je moet gleuven en hobbels zien.',
          yes:
            'Nog een tip: Als je je banden goed oppompt, hoef je minder zwaar te trappen. Je banden gaan ook minder snel lek en dus langer mee. ',
          no:
            'Je banden moeten goed opgepompt zijn en voldoende profiel hebben. Hiermee hoef je minder zwaar te trappen en loop je minder kans om te slippen. Pomp je banden of zet andere banden op je fiets die wel genoeg profiel hebben. Check ze daarna nog een keer.',
          viewBox: '280 125 300 300',
        },
      ],
    },
    {
      name: 'Het zadel',
      slug: 'zadel',
      steps: [
        {
          id: 1,
          label: 'Zadel zit vast',
          question: 'Zit je zadel goed vast en staat het recht?',
          explanation:
            'Pak het zadel vast en probeer het naar links en rechts te bewegen. Kijk of het recht naar voren wijst.',
          yes:
            'Nog een tip: Kijk ook of het zadel niet schuin staat. Als het wat naar boven of beneden staat glij je er makkelijk af.',
          no:
            'Je zadel moet goed vastzitten en recht naar voren wijzen, omdat je er anders vanaf kunt vallen en niet goed kunt sturen. Zet je zadel vast en check het daarna nog een keer.',
          viewBox: '60 15 190 190',
        },
        {
          id: 2,
          label: 'Zadelhoogte/framemaat',
          question: 'Staat het zadel op de goede hoogte?',
          explanation:
            'Ga op het zadel zitten en check of het op de goede hoogte staat. Je moet met je tenen goed bij de grond kunnen.',
          yes:
            'Nog een tip: Blijf op je zadel zitten tijdens het fietsen. Als je gaat staan, val je sneller.',
          no:
            'Je zadel moet op de goede hoogte staan, omdat je anders niet goed bij de trappers en de grond kunt. Draai het zadel los en stel het op de goede hoogte in. Zorg dat je het zadel daarna weer goed vast zet!',
          viewBox: '60 15 190 190',
        },
      ],
    },
    {
      name: 'Trappers en ketting',
      slug: 'trappers-en-ketting',
      steps: [
        {
          id: 1,
          label: 'Stroeve trappers',
          question: 'Zijn je trappers stroef?',
          explanation:
            'Test of de trappers stroef zijn door er met je voet overheen te bewegen. Je mag er niet van afglijden.',
          yes:
            'Nog een tip: Maak je trappers goed droog. Als ze nat zijn, glij je er sneller vanaf. Schoenen of laarzen met een rubberen zool zorgen voor meer grip op je trappers. ',
          no:
            'Je trappers moeten stroef zijn, omdat je er anders vanaf kunt glijden. Laat nieuwe trappers op je fiets zetten en check ze daarna nog een keer.',
          viewBox: '60 150 310 310',
        },
        {
          id: 2,
          label: 'Gele reflectoren',
          question: 'Zitten er twee gele reflectoren in je trappers?',
          explanation:
            'Kijk of je twee gele reflectoren ziet aan de voor- en achterkant van de trappers.',
          yes:
            'Nog een tip: maak de reflectoren regelmatig schoon, zodat ze goed zichtbaar blijven.',
          no:
            'Ze moeten reflectoren hebben om goed op te vallen in het donker.',
          viewBox: '60 150 310 310',
        },
        {
          id: 3,
          label: 'Ketting is goed afgesteld',
          question: 'Is je ketting goed op spanning?',
          explanation:
            'Check of de ketting op de goede spanning staat. Je kunt dit checken door de achterkant van de fiets op te tillen en te laten vallen. Als je ketting niet rammelt, is de spanning meestal goed.',
          yes:
            'Nog een tip: heb je een open kettingkast en draag je een wijde broek? Gebruik dan een broekclip of elastiek, zodat je broek niet tussen de ketting kan komen.',
          no:
            'Je ketting moet goed afgesteld staan. Met een slappe ketting loop je het risico op doortrappen. Ook kan je ketting van je fiets af gaan. Laat je ketting spannen en check ’m daarna nog een keer.',
          viewBox: '60 150 310 310',
        },
      ],
    },
    {
      name: 'De achterkant',
      slug: 'achterkant',
      steps: [
        {
          id: 1,
          label: 'Rode achterreflector',
          question:
            'Zit er een rode reflector aan de achterkant van de fiets of in het achterlicht?',
          explanation:
            'Check of je een rode achter-reflector ziet en of deze heel is.',
          yes:
            'Nog een tip: Heeft je rode achter-reflector de vorm van een driehoek? Vervang hem dan door een andere reflector. Hij mag namelijk geen driehoek zijn.',
          no:
            'Je moet een rode achter-reflector hebben om goed op te vallen in het donker. Laat een nieuwe op je fiets zetten en check ’m daarna nog een keer.',
          viewBox: '-55 95 160 160',
        },
        {
          id: 2,
          label: 'Achterlicht werkt',
          question: 'Werkt je achterlicht goed en geeft het rood licht? ',
          explanation:
            'Zet je achterlicht aan en kijk of hij het goed doet. Werkt je achterlicht met een dynamo? Vraag dan iemand om je voorwiel iets op te tillen en draai aan dit wiel om te kijken of je achterlicht het goed doet. ',
          yes:
            'Nog een tip: Je mag in noodgevallen ook een los lampje gebruiken in plaats van vaste fietsverlichting. Zorg er wel voor dat je die op een logische plek vastmaakt en dat hij niet knippert. Zorg er ook voor dat je altijd reservebatterijen in huis hebt.',
          no:
            'Bij het verkeersexamen is fietsverlichting niet verplicht, omdat het overdag is. Maar ook bij een flinke regenbui kan het best donker worden. Zorg er daarom voor dat je fietsverlichting altijd goed werkt. Laat je achterlicht repareren of gebruik tijdelijk een los lampje. Check je verlichting daarna nog een keer.',
          viewBox: '-55 95 160 160',
        },
      ],
    },
  ],
};

/**
 * Gets the part config by the given slug.
 *
 * @param {string} slug
 *   The slug to get the part for
 *
 * @returns {Object}
 *   The part object.
 */
export const getPartBySlug = slug => {
  return config.parts.find(part => part.slug === slug);
};

/**
 * Gets the step from a given part by index.
 * @param {Object} part
 *   The part to get the step from.
 * @param {int} index
 *   The index of the step to get.
 *
 * @returns {Object}}
 *   The step object.
 */
export const getStepFromPartByIndex = (part, index) => {
  return part.steps[parseInt(index) - 1];
};

/**
 * Gets the step by the given slug and index.
 *
 * @param {string} slug
 *   The slug of the part to get the step from.
 * @param {int} index
 *   The index of the step to get.
 *
 * @returns {Object}
 *   The step object.
 */
export const getStepBySlugAndIndex = (slug, index) => {
  const part = getPartBySlug(slug);
  if (part) {
    return getStepFromPartByIndex(part, index);
  }

  return undefined;
};

/**
 * Gets the total number of steps.
 *
 * @returns {number}
 *   The total number of steps.
 */
export const getTotalNumberOfSteps = () => {
  let numberOfSteps = 0;

  for (let i = 0; i < config.parts.length; i++) {
    numberOfSteps += config.parts[i].steps.length;
  }

  return numberOfSteps;
};

/**
 * Gets the data version.
 *
 * @returns {number}
 *   The data version.
 */
export const getDataVersion = () => {
  return config.data_version;
};

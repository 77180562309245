import React from 'react';
import styled from 'styled-components';
import { GridContainer } from "../GridContainer/GridContainer";
import { Link } from 'react-router-dom';

const StyledHeader = styled.header`
  position: absolute;
  width: 100%;
  padding-top: 20px;
  z-index: 10;

  h1 {
    display: table;
    position: relative;
    color: ${props => props.theme.color.white};
    a {
      position: relative;
      z-index: 1;
    }
  }

  .disc {
    background-color: ${props => props.theme.color.green};
    display: block;
    height: 534px;
    width: 262%;
    border-radius: 50%;
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);

    &.light {
      opacity: 0.3;
      margin-left: -40px;
    }
  }
`;

export default function PageHeader(props) {
  return (
    <StyledHeader>
      <GridContainer>
        <h1 className="h2">
          <Link to="/">VVN Fietscheck</Link>
          <span className="disc light" role="presentation"></span>
          <span className="disc" role="presentation"></span>
        </h1>
      </GridContainer>
    </StyledHeader>
  );
}

import React, { useState, useEffect } from 'react';
import Result from '../Result/Result';
import ApiUtils from '../Utils/ApiUtils';
import { Loading } from "../Loading/Loading";
import {getDataVersion} from "../../config/config";
import { BasicPage } from "../BasicPage/BasicPage";
import { GridContainer } from "../GridContainer/GridContainer";
import {useParams} from "react-router-dom";

export default function ResultSelector() {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [result, setResult] = useState({});

  let { id } = useParams();
  let { token } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let result = await ApiUtils.get('/api/result/' + id + '/' + token);
        let abort = false;
        if (result.status !== 200) {
          abort = true;
        }
        const json = result.data;

        if (json.meta.version && json.meta.version !== getDataVersion()) {
          abort = true;
        }

        if (abort) {
          setIsLoading(false);
          setIsError(true);
          return;
        }

        const processedResult = Object.create(null);
        json.data.forEach((part) => {
          processedResult[part.part_id] = [];
          part.steps.forEach((step) => {
            const processedStep = {
              id: step.step_id,
              value: step.value,
            };
            if (typeof step.note !== 'undefined') {
              processedStep.note = step.note;
            }
            processedResult[part.part_id].push(processedStep);
          });
        });

        setIsLoading(false);
        setResult(processedResult);
      } catch (e) {
        setIsLoading(false);
        setIsError(true);
      }
    };
    fetchData();
  }, [id, token]);

  if (isLoading) {
    return (
      <BasicPage>
        <GridContainer>
          <div className="message">
            <h1 className="h2">Bezig met het ophalen van het resultaat</h1>
            <div>
              <Loading />
            </div>
          </div>
        </GridContainer>
      </BasicPage>
    );
  }
  else {
    if (isError) {
      return (
        <BasicPage>
          <GridContainer>
            <div className="message">
              <h1 className="h2">Fout opgetreden</h1>
              <div className="text">
                <p>Er is een fout opgetreden bij het ophalen van het resultaat.</p>
              </div>
            </div>
          </GridContainer>
        </BasicPage>
      );
    }
    else {
      return (
        <Result results={result} />
      );
    }
  }
}

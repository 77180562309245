import 'react-app-polyfill/stable';
import 'eligrey-classlist-js-polyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './components/App/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module'
import 'typeface-poppins';
import './styles/normalize.css';
import './styles/main.css';

const gtmId = process.env.REACT_APP_GTM_ID;
if (gtmId !== undefined) {
  const tagManagerArgs = {
    gtmId: gtmId,
  };
  TagManager.initialize(tagManagerArgs);
}

let container = document.getElementById('root');
if (container !== null) {
  let root = createRoot(container);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { getPartBySlug, getStepFromPartByIndex } from '../../config/config';
import Step from '../Step/Step';
import NotFound from '../NotFound/NotFound';

export default function StepSelector() {
  const { slug, index } = useParams();
  const part = getPartBySlug(slug);

  if (part) {
    // Check if the index is a number.
    if (!isNaN(index)) {
      const step = getStepFromPartByIndex(part, index);
      // If a step is found. Show the step.
      if (step) {
        return <Step part={part} step={step} index={index}/>;
      }
    }

    // Go to the first step within the part.
    return <Navigate to={'/' + part.slug + '/stap/1'} />;
  }
  else {
    return <NotFound />;
  }
}

import { createStore } from 'redux';
import Reducer from '../reducers/Reducer';
import { persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

let pStore = null;

const persisConfig = {
  key: 'root',
  storage: storage,
  stageReconciler: autoMergeLevel2,
};

const pReducer = persistReducer(persisConfig, Reducer);

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  pStore = createStore(
    pReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
}
else {
  pStore = createStore(pReducer);
}

export const store = pStore;
export const persistor = persistStore(store);

import React from 'react';
import { connect } from 'react-redux';
import { setResult } from '../../actions/Actions';
import QuestionForm from '../QuestionForm/QuestionForm';
import { getStepResult } from '../Utils/ResultUtils';

const mapStateToProps = state => {
  return {
    results: state.results,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setResult: result => dispatch(setResult(result)),
  };
};

class QuestionFormController extends React.Component {
  constructor(props) {
    super(props);

    this.setResult = this.setResult.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
  }

  setResult(result) {
    this.props.setResult(result);
  }

  handleOptionChange(event) {
    this.setResult({
      slug: this.props.part.slug,
      id: this.props.step.id,
      value: event.target.value,
    });
  }

  render() {
    return (
      <QuestionForm
        question={this.props.step.question}
        explanation={this.props.step.explanation}
        handleOptionChange={this.handleOptionChange}
        result={getStepResult(
          this.props.results,
          this.props.part.slug,
          this.props.step.id
        )}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionFormController);

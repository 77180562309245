import React, { Fragment } from 'react';
import QuestionFormController from '../QuestionFormController/QuestionFormController';
import QuestionResult from '../QuestionResult/QuestionResult';
import Fact from '../Fact/Fact';
import { useSelector } from 'react-redux';
import { getStepResult } from '../Utils/ResultUtils';
import { Helmet } from 'react-helmet';
import StepStatus from "../StepStatus/StepStatus";
import styled from 'styled-components';
import { Breakpoint } from '../../styles/base/variables/layout';
import { width } from '../../styles/base/variables/layout';
import StatusVisual from '../StatusVisual/StatusVisual';
import { GridContainer } from "../GridContainer/GridContainer";

const PartContentOuter = styled.div`
  display: flex;
  > * {
    display: flex;
  }

  h1 {
    font-weight: normal;
  }
  h2 {
    color: ${props => props.theme.color.green};
    margin-top: 10px;
  }
`;
const PartContent = styled.div`
  width: 100%;
  flex-shrink: 0;
`;
const PartContentInner = styled.div`
  ${Breakpoint.greaterThan('large')`
    display: flex;
    width: 100%;
  `}

  // Visual.
  > .visual {
    display: flex;
    flex-direction: column;
    align-items: center;

    ${Breakpoint.greaterThan('large')`
      width: ${7/12*100}%;
      margin-right: 30px;
      align-items: flex-end;
    `}

    g#parts {
      display: none;
    }

    // Textual part inside visual (mobile only).
    > .textual {
      background-color: ${props => props.theme.color.appleGreen};
      margin-left: ${width.pagePadding * -1}vw;
      margin-right: ${width.pagePadding * -1}vw;
      padding-left: ${width.pagePadding}vw;
      padding-right: ${width.pagePadding}vw;
      width: calc(100% + ${width.pagePadding * 2}vw);
      box-shadow: 0 20px 10px ${props => props.theme.color.appleGreen};
      margin-top: 100px;
      padding-bottom: 20px;
      z-index: 1;
      ${Breakpoint.greaterThan('small')`
        padding-top: 5vw;
      `}

      .steps {
        margin-bottom: 20px;
      }

      .disc {
        &.to-come {
          &:not(.decided) {
            border-color: ${props => props.theme.color.appleGreenDimmedBlended};
          }
        }
        &.decided {
          &.to-come {
            background-color: ${props => props.theme.color.appleGreenDimmedBlended};
            border-color: ${props => props.theme.color.appleGreenDimmedBlended};
          }
        }
      }

      .bar {
        background-color: ${props => props.theme.color.appleGreenDimmedBlended};
        &.passed,
        &.active {
          background-color: ${props => props.theme.color.green};
        }
      }

      ${Breakpoint.greaterThan('large')`
        display: none;
      `}
    }
  }

  .visual-inner {
    background-color: ${props => props.theme.color.appleGreen};

    border-radius: 50%;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    box-shadow: inset -65px 0px 0px ${props => props.theme.color.appleGreenDimmed};

    width: 1070px;
    height: 1070px;
    flex-shrink: 0;

    padding-top: 770px;
    margin-top: -770px;

    ${Breakpoint.greaterThan('large')`
      max-height: calc(70vh + 335px);
      padding-top: 335px;
      margin-top: -335px;
    `}

    .status-outer {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .status-inner {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      overflow: hidden;
    }

    svg {
      overflow: visible;
      max-height: 100%;
      ${Breakpoint.greaterThan('large')`
        transform: translate(200px, 0);
      `}

      ${Breakpoint.greaterThan('gridMax')`
        transform: translate(130px, 0);
      `}
    }
  }

  // Textual part beside visual.
  > .textual {
    // On mobile textual part is show inside visual.
    ${Breakpoint.lessThan('979px')`
      > h1, > h2, > .steps, legend {
        display: none;
      }
    `}

    ${Breakpoint.greaterThan('large')`
      width: calc(${5/12*100}% - 30px);
      padding-top: 5vw;
    `}

    ${Breakpoint.greaterThan('gridMax')`
      padding-top: 150px;
      margin-right: 30px;
      width: ${4/12*100}%;
    `}

    .answer {
      margin-top: 30px ;
    }

    .fact {
      margin-top: 35px ;
    }
  }

  // Textual general styling.
  .textual {
    display: flex;
    flex-direction: column;
    .steps {
      order: -1;
      min-height: 27px;
    }
  }
`;

const Steps = styled.div`
  margin-bottom: 50px;
  > ol {
    display: flex;
    padding-left: 6px;
    > * {
      flex-grow: 1;
    }
  }
`;

export default function Step({ part, step, index }) {
    const results = useSelector((state) => state.results);

    const stepResult = getStepResult(
      results,
      part.slug,
      step.id
    );

    return (
      <Fragment>
        <Helmet>
          <title>
            {step.label} | {part.name} | VVN Fietscheck
          </title>
        </Helmet>
        <PartContentOuter>
          <GridContainer>
            <PartContent>
              <PartContentInner>
                <div className="visual">
                  <div className="textual">
                    <h1 className="h3">{part.name}</h1>
                    <h2>{step.question}</h2>
                    <Steps className={part.steps.length === 1 ? 'steps single': 'steps'}>
                      {part.steps.length > 1 &&
                        <ol>
                          {part.steps.map((step, stepIndex) => (
                            <li key={step.id}>
                              <StepStatus results={results} part={part} step={step} stepIndex={stepIndex} activeIndex={index}/>
                            </li>
                          ))}
                        </ol>
                      }
                    </Steps>
                  </div>
                  <div className="visual-inner">
                    <StatusVisual viewBox={step.viewBox}/>
                  </div>
                </div>
                <div className="textual">
                  <h1 className="h3">{part.name}</h1>
                  <Steps className={part.steps.length === 1 ? 'steps single': 'steps'}>
                    {part.steps.length > 1 &&
                      <ol>
                        {part.steps.map((partStep, stepIndex) => (
                          <li key={partStep.id}>
                            <StepStatus results={results} part={part} step={partStep} stepIndex={stepIndex} activeIndex={index}/>
                          </li>
                        ))}
                      </ol>
                    }
                  </Steps>
                  {stepResult === undefined && (
                    <Fragment>
                      <QuestionFormController
                        part={part}
                        step={step}
                      />
                      <Fact fact={step.fact}/>
                    </Fragment>
                  )}
                  {stepResult !== undefined && (
                    <Fragment>
                      <h2>{step.question}</h2>
                      <QuestionResult
                        part={part}
                        step={step}
                        index={index}
                        result={stepResult}
                      />
                    </Fragment>
                  )}
                </div>
              </PartContentInner>
            </PartContent>
          </GridContainer>
        </PartContentOuter>
      </Fragment>
    );
}

import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { allPartsCompleted } from '../Utils/ResultUtils';
import StatusVisual from "../StatusVisual/StatusVisual";
import ResetResultLink from "../ResetResultLink/ResetResultLink";
import { GridContainer } from "../GridContainer/GridContainer";
import { ButtonOuterGreen, ButtonOuterGreenGhost } from "../ButtonOuter/ButtonOuter";
import styled from 'styled-components';
import { Breakpoint } from '../../styles/base/variables/layout';
import PostResultsLink from "../PostResultsLink/PostResultsLink";

const OverviewContentOuter = styled.div`
  ${Breakpoint.greaterThan('large')`
    background-color: ${props => props.theme.color.appleGreenDimmed};
  `}
`;

const OverviewContent = styled.div`
  background-color: ${props => props.theme.color.appleGreen};
  overflow: hidden;

  ${Breakpoint.lessThan('large')`
    width: 1070px;
    padding-top: 35px;
    margin-top: -35px;
    margin-left: calc(50% - 535px);
    padding-left: calc(535px - 50%);
    padding-right: calc(535px - 50%);
    border-radius: 0 0 535px 535px;
    box-shadow: 65px 0px 0px ${props => props.theme.color.appleGreenDimmed};
  `}

  ${Breakpoint.greaterThan('large')`
    padding-left: 10%;
    padding-right: 10%;
    margin-left: -10%;
    width: 120%;
    border-radius: 0 0 40% 0;
  `}

  h1 {
    color: ${props => props.theme.color.green};
    ${Breakpoint.greaterThan('gridMax')`
      padding-left: 100px;
    `}

    span {
      font-weight: normal;
    }
  }

  .status-inner {
    ${Breakpoint.greaterThan('medium')`
      margin-top: -50px;
    `}
    ${Breakpoint.greaterThan('gridMax')`
      margin-top: 0px;
      margin-bottom: 0px;
    `}
  }

  button,
  .button {
    display: table;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    ${Breakpoint.greaterThan('gridMax')`
      margin-top: 80px;
    `}
  }
`;

const OverviewContentInner = styled.div`
  padding-top: 100px;
  padding-bottom: 30px;

  ${Breakpoint.greaterThan('small')`
    padding-top: 150px;
    padding-bottom: 60px;
  `}
`;

export default function Overview() {
  let results = useSelector((state) => state.results);
  let remote = useSelector((state) => state.remote);

  // If the results have already been submitted, redirect to the result.
  if (remote.id !== undefined) {
    return (
      <Navigate to="/resultaat" />
    );
  }

  const resultsEmpty = (Object.entries(results).length === 0 && results.constructor === Object);

  return (
    <Fragment>
      <Helmet>
        <title>VVN Fietscheck</title>
      </Helmet>
        <OverviewContentOuter>
          <OverviewContent>
            <OverviewContentInner>
            <GridContainer>
              <h1 className="h2">Kies je onderdeel<br /><span>&amp; check je fiets</span></h1>
              <StatusVisual interactive={true} results={results}/>
              <ButtonOuterGreen>
                <PostResultsLink text="Check je score" noErrorMessage={true}/>
              </ButtonOuterGreen>
              {(!allPartsCompleted(results) && !resultsEmpty) && (
                <ButtonOuterGreenGhost>
                  <ResetResultLink text="Begin opnieuw"/>
                </ButtonOuterGreenGhost>
              )}
            </GridContainer>
            </OverviewContentInner>
          </OverviewContent>
        </OverviewContentOuter>
    </Fragment>
  );
}

import styled from 'styled-components';
import { Breakpoint } from '../../styles/base/variables/layout';

export const BasicPage = styled.div`
  background-color: ${props => props.theme.color.appleGreen};
  padding-top: 100px;
  padding-bottom: 30px;

  ${Breakpoint.greaterThan('small')`
    padding-top: calc(10vh + 150px);
    padding-bottom: 60px;
  `}

  h1 {
    color: ${props => props.theme.color.green};
    display: inline;
  }

  .loader {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
  }

  .message {
    text-align: center;
    .text {
      margin-top: 20px;
    }
  }
`;

import { SET_RESULT, DELETE_RESULT, SET_REMOTE, DELETE_RESULTS } from '../actions/Actions';

const initialState = (window.Cypress && window.initialState) || {
  resultState: {
    results: {},
    remote: {},
  },
};

const Reducer = (state = initialState.resultState, action) => {
  let newResults = Object.assign({}, state.results);

  switch (action.type) {
    case SET_RESULT:
      const partResult = newResults[action.result.slug];
      if (partResult === undefined) {
        newResults[action.result.slug] = [];
      }

      let stepResultIndex = newResults[action.result.slug].findIndex(
        step => step.id === action.result.id
      );
      if (stepResultIndex !== -1) {
        newResults[action.result.slug][stepResultIndex].value =
          action.result.value;
      }
      else {
        newResults[action.result.slug].push({
          id: action.result.id,
          value: action.result.value,
        });
      }

      return Object.assign({}, state, {
        results: newResults,
      });

    case DELETE_RESULT:
      const partResult2 = newResults[action.slugAndId.slug];
      if (partResult2 !== undefined) {
        newResults[action.slugAndId.slug] = newResults[
          action.slugAndId.slug
        ].filter(step => step.id !== action.slugAndId.id);
      }

      return Object.assign({}, state, {
        results: newResults,
      });

    case SET_REMOTE:
      return Object.assign({}, state, {
        remote: action.remote,
      });

    case DELETE_RESULTS:
      return Object.assign({}, state, {
        results: {},
        remote: {},
      });

    default:
      return state;
  }
};

export default Reducer;

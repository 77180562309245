import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { deleteResult } from '../../actions/Actions';
import { ReactComponent as IconCheck } from '../../assets/images/check.svg';
import { ReactComponent as IconCross } from '../../assets/images/cross.svg';
import { ReactComponent as IconArrowRight } from '../../assets/images/arrow-right.svg';

const Answer = styled.div`
  .answer {
    color: ${props => props.$statusColor};
    display: flex;
    align-items: center;

    > .text {
      margin-right: 10px;
    }
  }

  .answer-text {
    margin-top: 20px;
  }

  .actions {
    margin-top: 40px;
    margin-right: -20px;
    margin-bottom: -20px;
    > * {
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }

  button {
    border-color: ${props => props.theme.color.lightGrey};
  }

  .button {
    background-color: ${props => props.theme.color.green};
    color: ${props => props.theme.color.white};
    border-color: ${props => props.theme.color.green};
  }
`;

const mapDispatchToProps = dispatch => {
  return {
    deleteResult: slugAndId => dispatch(deleteResult(slugAndId)),
  };
};

class QuestionResult extends React.Component {
  constructor(props) {
    super(props);

    this.buttonClickHandler = this.buttonClickHandler.bind(this);
  }

  getNextPath() {
    const index = parseInt(this.props.index);
    let nextPath = '/';
    if (index < this.props.part.steps.length) {
      const nextStepIndex = index + 1;
      nextPath = '/' + this.props.part.slug + '/stap/' + nextStepIndex;
    }

    return nextPath;
  }

  getNextLinkTitle(path) {
    if (path === '/') {
      return 'Volgende onderdeel';
    }

    return 'Volgende stap';
  }

  buttonClickHandler() {
    this.props.deleteResult({
      slug: this.props.part.slug,
      id: this.props.step.id,
    });
  }

  render() {
    const nextPath = this.getNextPath();
    const nextLinkTitle = this.getNextLinkTitle(nextPath);
    const statusColor = this.props.result === 'yes' ? props => props.theme.color.green : props => props.theme.color.orange;

    return (
      <Answer $statusColor={statusColor}>
        <div className="answer">
          <span className="text h3">
            {this.props.result === 'yes' ? 'Ja' : 'Nee'}
          </span>
          <span className="icon" role="presentation">
            {this.props.result === 'yes' ? <IconCheck/> : <IconCross/>}
          </span>
        </div>
        <div className="answer-text">
          <strong>
            {this.props.result === 'yes' ? 'Goed zo!' : 'Dat is jammer!'}
          </strong>{' '}
          {this.props.step[this.props.result]}
        </div>
        <div className="actions">
          <Link to={nextPath} className="button">
            <span className="text">{nextLinkTitle}</span>
            <span className="icon" role="presentation"><IconArrowRight/></span>
          </Link>
          <button onClick={this.buttonClickHandler}>Opnieuw</button>
        </div>
      </Answer>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps
)(QuestionResult);

import React, {useEffect} from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Breakpoint } from '../../styles/base/variables/layout';

import { getPartStatus } from '../Utils/ResultUtils';

const StyledStatus = styled.div`
  ${props => props.$centered === 'true' && `
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  `}
  ${Breakpoint.greaterThan('gridMax')`
    ${props => props.$centered === 'true' && `
      max-width: 800px;
    `}
  `}

  @keyframes pulse {
    from {
      stroke-width: 10;
    }
    to {
      stroke-width: 30;
    }
  }
  svg.status-visual path.icon {
    fill:#ffffff;
    stroke: #ffffff;
    display:none
  }
  svg.status-visual path.icon.visible {
    display:block;
  }
  svg.status-visual circle {
    fill:#009cca;
    stroke: #009cca;
    stroke-width:30;
    stroke-opacity:0.2;
  }
  svg.status-visual circle.fail {
    fill:#ee7f00;
    stroke: #ee7f00;
  }
  svg.status-visual circle.pass {
    fill:#009444;
    stroke: #009444;
  }
  svg.status-visual circle.pulsating {
    stroke-width: 10;
    animation-name: pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  svg.status-visual text.visually-hidden {
    font-size: 0;
  }

  &.default-viewbox {
    .status-inner {
      position: relative;
      height: 0;
      padding-bottom: ${406/606*100}%;

      svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export default function StatusVisual({ results, viewBox, interactive }) {
  useEffect(() => {
    if (results === undefined) {
      return;
    }

    const partsWrapper = document.getElementById('parts');
    const parts = partsWrapper.getElementsByTagName('g');

    for (let i = 0; i < parts.length; i++) {
      const status = getPartStatus(results, parts[i].id);
      const circles = parts[i].getElementsByTagName('circle');
      if (circles.length) {
        const circle = circles[0];
        circle.classList.remove('pass');
        circle.classList.remove('fail');
        circle.classList.add(status);
        const icons = parts[i].getElementsByTagName('path');
        for (let j = 0; j < icons.length; j++) {
          if (icons[j].classList.contains(status)) {
            icons[j].classList.add('visible');
          }
          else {
            icons[j].classList.remove('visible');
          }
        }
      }
    }
  });

  let actualViewBox = "0 0 606 406";
  let centered = "true";
  let statusOuterClasses = ['status-outer'];
  if (viewBox) {
    actualViewBox = viewBox;
    centered = "false";
  } else {
    statusOuterClasses.push('default-viewbox');
  }

  return (
    <StyledStatus className={statusOuterClasses} $centered={centered}>
      <div className="status-inner">

        <svg version="1.1" className="status-visual" xmlns="http://www.w3.org/2000/svg" viewBox={actualViewBox}>
        <g transform="translate(289.000000, 119.706320)">
          <polyline fill="#000002" points="114.4,35.8 -17.3,153 -23.5,145.8 110.6,26.7  "/>
          <path fill="#000002" d="M113.3,35.1C69.5,74.3,25.5,113.2-18.4,152.3c0.8,0,1.9,0,2.8,0c-1.1-1.3-6.4-6.6-6.4-6.1
            c0,0.4,0,0.6,0.8-0.4c1.5-2.5,5.5-4.9,7.6-6.8c10.2-9.1,20.5-18.2,30.7-27.3c25.4-22.6,51-45.3,76.5-67.9
            c5.9-5.3,12.1-10.8,18-16.1c1.7-1.5-1.1-2.8-2.3-1.5C64.6,66,19.9,105.6-24.7,145.4c-0.4,0.4-0.8,1.1-0.4,1.5
            c2.1,2.3,4.2,4.9,6.1,7.2c0.6,0.6,2.1,0.6,2.8,0C27.6,114.9,71.6,76,115.5,36.8C117.4,34.9,114.6,33.9,113.3,35.1L113.3,35.1z"/>
        </g>
        <line opacity="0.5" fill="none" stroke="#FFFFFF" strokeWidth="0.5743" enableBackground="new" x1="400.9" y1="150.2" x2="275.1" y2="262"/>
        <g transform="translate(416.000000, 81.706320)">
          <path fillRule="evenodd" clipRule="evenodd" fill="#E6E7E8" d="M6.8,48.5c0,0,6.6-3.9,8.4-1.5c1.6,2.4,6.2,12.6,1.5,16
            C11.7,66.3,2,62.3,2,62.3L6.8,48.5z"/>
          <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M8.2,48.5c1.5-0.9,3.7-2.2,5.1-0.7c1.3,1.3,2,3.8,2.6,5.5
            c0.7,2.4,1.5,5.7,0.4,8.1c-1.7,3.3-6.4,2-9.2,1.3c-0.9-0.2-1.7-0.5-2.6-0.7c-0.9-0.4-0.4,0.2-0.4-0.2c0-1.3,1.1-3.1,1.5-4.2
            c1.1-3.1,2.2-6,3.3-9.2c0.2-0.7-3.7-0.4-4,0.7C3.3,53.6,1.6,58.2,0,62.8C0,63,0,63,0,63.2c6.4,2.6,21.1,4.6,20.7-6
            c-0.2-2.9-1.5-10.5-5.1-11.4c-3.3-0.9-7.7,1.3-10.4,2.9c-0.7,0.5-0.4,0.7,0.2,0.9C6.2,49.6,7.5,49.1,8.2,48.5z"/>
        </g>
        <g transform="translate(402.000000, 80.706320)">
          <path fillRule="evenodd" clipRule="evenodd" fill="#4B4E50" d="M15.8,65.1c0,0-9.3-1.1-12.1-3.1c-2.6-2,4.1-14.8,4.1-14.8
            L22.1,47L15.8,65.1z"/>
          <path fillRule="evenodd" clipRule="evenodd" fill="#565959" d="M17.1,64.2c-3.5-0.4-12.3-0.7-11.9-5.5c0.4-4,2.8-8.4,4.6-11.9
            c-0.9,0.4-1.9,0.9-3,1.3c4.8,0,9.7-0.2,14.3-0.2c-0.4-0.2-0.7-0.4-1.1-0.5c-2.2,6-4.3,12.3-6.5,18.3c-0.4,0.9,3.7,0.5,4.1-0.7
            c2.2-6,4.3-12.3,6.5-18.3C24.3,46,23.2,46,23,46c-4.8,0-9.7,0.2-14.3,0.2c-0.9,0-2.4,0.4-3,1.3C3.9,51.2,0.2,57,0.9,61.2
            c0.7,4,10,4.4,13.4,5C15.4,66,19.1,64.4,17.1,64.2z"/>
        </g>
        <g transform="translate(425.000000, 83.706320)">
          <path fillRule="evenodd" clipRule="evenodd" fill="#E6E7E8" d="M5.6,46.2c0,0-7,8.7-3.1,17.1"/>
          <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M5.1,46.1C1.4,50.9-0.5,57.5,2,63.4c0.2,0.4,1.4,0.4,1.2,0.2
            c-2.3-5.7-0.5-12.3,3.2-16.9C6.5,46.1,5.2,45.7,5.1,46.1L5.1,46.1z"/>
        </g>
        <g transform="translate(309.000000, 28.706320)">
          <path fillRule="evenodd" clipRule="evenodd" fill="#646869" d="M32.1,46.3c0,0,4.5,9.7,2.7,12.6C33,61.7,5,74.3,2.3,71.4
            s0.9-4.8,0.9-4.8l25.3-8.7l1.6-8.3L32.1,46.3z"/>
          <path fillRule="evenodd" clipRule="evenodd" fill="#565959" d="M31.4,46.3c1.4,2.9,3.3,7,3.1,10.4c-0.2,3.1-3.1,4.1-5.2,5.2
            c-7.1,3.9-15,7.5-22.8,9.1c-2.4,0.6-6.2-1-2.9-3.9C4,66.7,4.5,66.7,5,66.5c2.7-1.2,5.6-1.9,8.3-2.9c5.2-1.7,10.7-3.7,15.9-5.4
            c0.2,0,0.2-0.2,0.4-0.2c0.9-4.3,1.3-7.7,3.6-11.4C33.6,46.3,31.8,45.9,31.4,46.3c-1.8,2.9-2.4,4.6-2.9,7.9
            c-0.7,3.9-0.2,3.3-3.6,4.6c-7.1,2.5-14.5,4.3-21.4,7.3c-1.6,0.8-3.6,2.1-2.5,4.4c0.9,1.9,3.1,1.9,4.7,1.7c8-1,16.1-5,23.2-8.7
            c2.2-1.2,6.3-2.5,7.4-5c1.4-3.5-1.6-9.3-2.9-12.2C32.8,45.7,31,45.9,31.4,46.3z"/>
        </g>
        <path fill="#9baaa0" opacity="0.4" d="M0,389.5c0,9.2,133.7,16.5,298.5,16.5S597,398.5,597,389.5c0-9.2-133.7-16.5-298.5-16.5S0,380.3,0,389.5z"/>
        <g transform="translate(41.000000, 139.706320)">
          <line fill="none" x1="66.5" y1="120.7" x2="2" y2="46.9"/>
          <path fill="#000002" d="M68.1,120c-21.4-24.6-43-49.2-64.5-73.9c-0.9-0.9-4.4,0.4-3.3,1.5c21.4,24.6,42.9,49.2,64.3,73.9
            C65.6,122.4,69,121.1,68.1,120L68.1,120z"/>
        </g>
        <g transform="translate(374.000000, 136.706320)">
          <g>
            <path fill="#BCBEC0" d="M47.5,87.4c36.7,40,73.5,79.9,110.2,119.9c5.3,5.7,10.7,11.6,15.8,17.3c0.7,0.7,4.4-0.7,3.9-1.5
              c-36.7-40-73.5-79.9-110.2-119.9C61.8,97.5,56.5,91.7,51.3,86C50.4,85.2,46.7,86.7,47.5,87.4L47.5,87.4z"/>
            <path fill="#BCBEC0" d="M167.2,75.1c-29.1,44.8-58,89.8-87.2,134.6c-4.1,6.4-8.3,12.7-12.3,19.1c-0.9,1.5,3.1,1.1,3.7,0
              c29.1-44.8,58-89.8,87.2-134.6c4.1-6.4,8.3-12.7,12.3-19.1C172,73.7,168,74,167.2,75.1L167.2,75.1z"/>
            <path fill="#FFFFFF" d="M48.4,87.1C85,127.1,121.9,167,158.6,207c5.3,5.7,10.7,11.6,15.8,17.3c0.4,0.6,2.4-0.2,1.8-0.7
              c-36.7-40-73.5-79.9-110.2-119.9C60.7,97.9,55.4,92,50.2,86.3C49.9,85.8,47.8,86.5,48.4,87.1L48.4,87.1z"/>
            <path fill="#BCBEC0" d="M102.5,54c5.2,55.1,10.3,110.2,15.5,165.3c0.7,7.7,1.5,15.4,2.2,23.3c0.2,1.8,4.6,0.7,4.4-1.1
              c-5.2-55.1-10.3-110.2-15.5-165.3c-0.7-7.7-1.5-15.4-2.2-23.3C106.8,51.1,102.4,52.2,102.5,54L102.5,54z"/>
            <path fill="#FFFFFF" d="M103.7,53.8c5.2,55.1,10.3,110.2,15.5,165.3c0.7,7.7,1.5,15.4,2.2,23.3c0,0.7,2.2,0.4,2.2-0.6
              c-5.2-55.1-10.3-110.2-15.5-165.3c-0.7-7.7-1.5-15.4-2.2-23.3C105.9,52.4,103.7,52.9,103.7,53.8L103.7,53.8z"/>
            <path fill="#BCBEC0" d="M211.4,144.7c-56.7,7.3-113.5,14.5-170.2,21.9c-8.1,1.1-16.4,2-24.5,3.1c-1.8,0.2-3.9,2.4-0.7,2
              c56.7-7.3,113.5-14.5,170.2-21.9c8.1-1.1,16.4-2,24.5-3.1C212.7,146.4,214.6,144.4,211.4,144.7L211.4,144.7z"/>
            <path fill="#FFFFFF" d="M208.3,145.3c-55.5,6.8-111.1,13.8-166.6,20.6c-8.1,0.9-16.2,2-24.1,2.9c-1.3,0.2-1.7,1.8-0.2,1.7
              c55.5-6.8,111.1-13.8,166.6-20.6c8.1-0.9,16.2-2,24.1-2.9C209.2,146.8,209.8,145.1,208.3,145.3L208.3,145.3z"/>
            <path fill="#BCBEC0" d="M208.5,181.8c-53.8-15.2-107.6-30.5-161.4-45.7c-7.7-2.2-15.7-4.4-23.4-6.6c-1.1-0.4-5,1.5-3.5,1.8
              C74,146.5,127.8,161.8,181.6,177c7.7,2.2,15.7,4.4,23.4,6.6C205.9,183.8,210,182.2,208.5,181.8L208.5,181.8z"/>
            <path fill="#FFFFFF" d="M214.8,184.6c-56-16.2-112.2-32.1-168.2-48.3c-7.9-2.2-16-4.6-24-6.8c-0.9-0.4-2.6,1.1-1.3,1.5
              c56,16.2,112.2,32.1,168.2,48.3c7.9,2.2,16,4.6,24,6.8C214.4,186.4,216,185.1,214.8,184.6L214.8,184.6z"/>
            <path fill="#BCBEC0" d="M28.1,210.3c49.6-29,98.9-58,148.5-87c7-4,14-8.3,21.2-12.3c2.6-1.5-1.3-2-2.8-1.3
              c-49.6,29-98.9,58-148.5,87c-7,4-14,8.3-21.2,12.3C22.8,210.5,26.6,211,28.1,210.3L28.1,210.3z"/>
            <path fill="#FFFFFF" d="M26.8,209.2c49.7-28.8,99.3-57.5,149.1-86.3c7-4,14.2-8.3,21.2-12.3c1.3-0.7-0.7-1.1-1.3-0.6
              c-49.7,28.8-99.3,57.5-149.1,86.3c-7,4-14.2,8.3-21.2,12.3C24.2,209.2,26.1,209.6,26.8,209.2L26.8,209.2z"/>
            <path fill="#FFFFFF" d="M168.1,75.1c-29.1,44.8-58,89.8-87.2,134.6c-4.1,6.4-8.3,12.7-12.3,19.1c-0.6,0.7,1.5,0.6,1.8,0
              c29.1-44.8,58-89.8,87.2-134.6c4.1-6.4,8.3-12.7,12.3-19.1C170.5,74.4,168.5,74.6,168.1,75.1L168.1,75.1z"/>
            <g>
              <path fill="#D1D3D4" d="M218.1,121.4c-14.4-40.4-53.6-66.8-96.2-68.5c-40.9-1.5-84.4,18.2-103,55.8
                c-19.2,38.7-6.1,85.2,26.5,112.2c33.5,27.5,80.9,31,119.8,13.8C209.4,215.2,233.2,167.9,218.1,121.4c-1.3-4-18.1,0.7-17,4.6
                c11.4,34.9,1.1,74.2-28.9,96.6c-31,23.1-73.3,19.1-103.9-2.2c-30.4-20.9-47.9-59.1-39.4-95.5c8.3-35.4,39.2-63.5,76.3-64.6
                c42.4-1.3,81.8,26.4,96,65.9C202.6,130,219.4,125.1,218.1,121.4z"/>
              <g>
                <path fill="#DDD1BE" d="M224,119.4c-19-55.6-84-84.8-143.2-68.7S-11,125.1,8,180.7s82.4,87.4,141.7,71.2
                  C208.9,235.6,243.1,174.8,224,119.4z M146.2,240.4c-52.5,14.3-108.7-14-125.5-63.2s12-100.8,64.5-115.1s108.7,14,125.5,63.2
                  S198.5,226.1,146.2,240.4z"/>
                <g>
                  <path fill="#9B8579" d="M226.2,118.7C193.9,28.1,52.4,19,10.2,106.4c-21,43.5-5.7,95.5,31.7,124.9c35.9,28.3,87.2,34,128.4,14
                    C217.2,222.4,242.6,168.8,226.2,118.7c-0.4-1.3-4.6-0.2-4.2,1.1c13.5,41.1-1.3,86.3-35.6,112.7c-38.1,29.2-92.7,28.8-132.5,2.9
                    C18.3,212.1-2.3,169.2,7.1,127.1c10.3-45.9,52.5-76.6,98.6-79.5c49.6-3.1,99.3,24.8,116.3,72.3
                    C222.5,121.2,226.6,119.9,226.2,118.7z"/>
                  <path fill="#9B8579" d="M145.8,239.5c-39.1,10.1-82.2-2.9-107.6-34.5c-23.4-29.2-27.3-70.5-7-102.3
                    C54.5,66.1,101.3,52,142,64.1c35.4,10.5,64.3,39.8,70.2,76.8C219.5,186,189,227,145.8,239.5c-2.6,0.7-2,2.6,0.6,1.8
                    c38.5-11,69.5-43.2,71.1-84.5c1.7-42-27.3-79-66.1-92.9C114,50.4,69.2,58.1,40.5,86.3c-29.7,29-35,74.5-13.5,109.8
                    c24.5,40.2,74.6,56.7,119.2,45.2C149,240.8,148.4,238.7,145.8,239.5z"/>
                </g>
              </g>
            </g>
            <g transform="translate(104.023622, 99.991379)">
              <path fill="#7C8081" d="M21.4,52.3c-2.8-4.4-9-6.2-14-4C2.6,50.4,0.9,55.6,3.7,60s9,6.2,14,4C22.7,62,24.3,56.7,21.4,52.3z"/>
              <path fill="#BCBEC0" d="M23.4,51.4c-3.7-5.1-10.7-6.6-16.4-4.2C1.5,49.4-1.8,55.5,1.8,61C8.8,71.4,31,64.4,23.4,51.4
                c-0.7-1.3-4.8,0.2-3.9,1.7c2.2,3.7,1.3,8.6-3.1,10.3c-3.9,1.3-8.5-0.9-10.5-4.2C3.7,55.8,4.4,50.8,8.5,49s8.7,0.6,11.2,4
                C20.3,54.3,24.5,52.8,23.4,51.4z"/>
            </g>
            <path fill="#D1D3D4" d="M119.7,155.9c-0.4-2.4-1.8-4.2-3.5-4s-2.8,2.2-2.4,4.6c0.4,2.4,1.8,4.2,3.5,4
              C118.9,160.3,120.1,158.3,119.7,155.9z"/>
          </g>
          <g transform="translate(92.968504, 85.301724)">
            <path fill="#7C8081" d="M2.4,63.3C1.3,73.8,9.8,82.9,21.6,83.9c11.8,0.9,22.3-6.8,23.4-17.3S37.6,47,25.8,46S3.5,52.8,2.4,63.3z"
              />
            <path fill="#BCBEC0" d="M0.6,63.5C-0.7,90.5,44,91.7,47,66.2C50.1,39.2,5,38.7,0.6,63.5c-0.2,1.3,3.5,0.6,3.7-0.6
              c4.1-22.8,41.5-19.5,39.1,3.9S3.1,86.1,4.2,62.9C4.2,61.6,0.6,62.4,0.6,63.5z"/>
          </g>
          <g transform="translate(107.708661, 98.155172)">
            <path fill="#A7A9AC" d="M2.8,51.2c-0.4,3.1,2,5.7,5.3,6.1c3.3,0.2,6.3-2,6.6-5.1c0.4-3.1-2-5.7-5.3-6.1
              C6.1,45.8,3.1,48.2,2.8,51.2z"/>
            <path fill="#BCBEC0" d="M0.9,51.7c-0.6,8.8,14.7,7.9,15.7,0c1.1-9-14.4-7.9-15.7-0.2c-0.2,0.9,3.5,0.4,3.7-0.6
              c0.4-2.6,2.6-4.8,5.3-3.5c1.8,0.9,2.9,2.9,2.8,5.1c-0.4,2.9-2.9,4.8-5.5,3.1c-1.8-1.1-2.8-2.9-2.6-5C4.8,50.1,1.1,50.6,0.9,51.7z"
              />
          </g>
          <polygon stroke="#000000" strokeWidth="0.7593" points="151.2,232.3 146.6,224.6 142,225.3 146.6,233.8   "/>
          <g transform="translate(145.559055, 41.232759)">
            <g>
              <path fill="#FBB040" d="M25.3,80.7c0,0-9-19.8-22.8-22.6c0,0,7-6.8,12.7-10.8S40.5,72.5,36.8,78L25.3,80.7z"/>
              <path fill="#F7941D" d="M27.7,80.2C23.1,70.1,15.2,59.8,4,56.9c0.2,0.6,0.2,0.9,0.4,1.5c1-1.2,2.3-2.3,3.6-3.4
                c1.7-1.5,5.7-6.2,7.9-6.6c2.8-0.4,7,5.5,8.7,7.5c3.5,4.2,13.1,16.2,9.9,22.4c0.7-0.6,1.5-0.9,2-1.5c-3.9,0.9-7.6,1.8-11.4,2.8
                c-2.8,0.7-2.2,2.8,0.6,2.2c3.5-0.9,12.3-1.1,13.8-5c2-5.3-4.6-14.1-7.2-18.2c-2.9-4.4-7.9-12.3-13.8-13
                C11.9,44.9,5,53.7,0.8,57.7c-0.7,0.7-0.6,1.3,0.4,1.5c10.3,2.6,17.9,12.9,21.9,22C23.7,82.9,28.3,81.3,27.7,80.2z"/>
            </g>
            <polygon opacity="0.4" fill="#ECECEB" enableBackground="new" points="23.7,75.8 26.8,56.3 21.3,49.5 18.2,69     "/>
          </g>
        </g>
        <g transform="translate(0.000000, 126.706320)">
          <g transform="translate(0.000000, 2.833333)">
            <path fill="#BCBEC0" d="M47.5,88.1c36.8,40.7,73.7,81.6,110.3,122.3c5.2,5.9,10.5,11.5,15.7,17.4c1.1,1.1,5-0.4,3.9-1.6
              c-36.8-40.7-73.7-81.6-110.3-122.3C61.9,98,56.5,92.3,51.4,86.5C50.3,85.2,46.4,86.8,47.5,88.1L47.5,88.1z"/>
            <path fill="#BCBEC0" d="M167.4,75.5c-28.9,45.6-58,91.3-86.9,136.9c-4.1,6.4-8.3,13-12.3,19.4c-0.9,1.6,3.1,1.3,3.9,0
              c28.9-45.7,58-91.3,86.9-137c4.1-6.4,8.3-13,12.3-19.4C172.2,74,167.9,74.4,167.4,75.5L167.4,75.5z"/>
            <path fill="#FFFFFF" d="M48.4,87.6c36.8,40.7,73.7,81.6,110.3,122.3c5.2,5.9,10.5,11.6,15.7,17.4c0.6,0.6,2.4-0.2,1.8-0.7
              c-36.8-40.7-73.7-81.6-110.3-122.3c-5.2-5.9-10.5-11.6-15.7-17.4C49.9,86.3,47.9,87,48.4,87.6L48.4,87.6z"/>
            <path fill="#BCBEC0" d="M102.6,54c5.2,55.9,10.3,111.8,15.5,167.8c0.7,8.1,1.5,16.1,2.2,24c0.2,1.1,4.6,0.2,4.4-1.1
              c-5.2-55.9-10.3-111.8-15.5-167.8c-0.7-8.1-1.5-16.1-2.2-24C107,51.8,102.6,52.7,102.6,54L102.6,54z"/>
            <path fill="#FFFFFF" d="M103.7,53.8c5.2,55.9,10.3,111.8,15.5,167.8c0.7,8.1,1.5,16.1,2.2,24c0,0.9,2.2,0.4,2.2-0.6
              c-5.2-55.9-10.3-111.8-15.5-167.8c-0.7-8.1-1.5-16.1-2.2-24C105.9,52.4,103.7,52.7,103.7,53.8L103.7,53.8z"/>
            <path fill="#BCBEC0" d="M211.6,146.4c-56.7,7.3-113.4,14.9-170.1,22.2c-8.1,1.1-16.4,2.2-24.5,3.1c-1.8,0.2-3.9,2.4-0.7,1.8
              c56.7-7.3,113.4-14.8,170.1-22.2c8.1-1.1,16.4-2.2,24.5-3.1C212.7,148.1,214.7,146,211.6,146.4L211.6,146.4z"/>
            <path fill="#FFFFFF" d="M208.4,147.1c-55.4,7-111,13.9-166.5,20.9c-8.1,1.1-16.2,2-24.1,3.1c-1.1,0.2-1.8,1.5-0.4,1.3
              c55.4-7,111-13.9,166.5-20.9c8.1-1.1,16.2-2,24.1-3.1C209.2,148.2,209.9,147,208.4,147.1L208.4,147.1z"/>
            <path fill="#BCBEC0" d="M208.4,184c-53.8-15.6-107.5-31-161.3-46.6c-7.7-2.2-15.7-4.6-23.4-6.8c-1.3-0.4-5.2,1.5-3.5,2
              c53.8,15.6,107.5,31,161.3,46.6c7.7,2.2,15.7,4.6,23.4,6.8C206.2,186.4,210.3,184.5,208.4,184L208.4,184z"/>
            <path fill="#FFFFFF" d="M215.1,187.5c-56.2-16.5-112.3-32.8-168.5-49.3c-7.9-2.4-15.8-4.6-23.8-7c-0.6-0.2-2.4,0.7-1.8,0.7
              c56.2,16.5,112.3,32.8,168.5,49.3c7.9,2.4,15.8,4.6,23.8,7C213.8,188.4,215.6,187.7,215.1,187.5L215.1,187.5z"/>
            <path fill="#BCBEC0" d="M28.4,213c49.5-29.5,98.9-59,148.4-88.6c7-4.2,14-8.4,21.2-12.7c2.6-1.5-1.3-2.2-2.8-1.3
              c-49.5,29.5-98.9,59-148.4,88.6c-7,4.2-14,8.4-21.2,12.6C23,213.3,26.9,213.9,28.4,213L28.4,213z"/>
            <path fill="#FFFFFF" d="M26.9,211.9c49.5-29.1,99.1-58.5,148.6-87.6c7.2-4.2,14.4-8.4,21.5-12.7c1.3-0.7-0.7-1.1-1.3-0.7
              c-49.5,29.1-99.1,58.5-148.6,87.6c-7.2,4.2-14.4,8.4-21.5,12.6C24.3,212,26.3,212.2,26.9,211.9L26.9,211.9z"/>
            <path fill="#FFFFFF" d="M168.3,75.5c-28.9,45.6-58,91.3-86.9,136.9c-4.1,6.4-8.3,13-12.3,19.4c-0.6,0.7,1.5,0.6,1.8,0
              c28.9-45.7,58-91.3,86.9-137c4.1-6.4,8.3-13,12.3-19.4C170.7,74.7,168.7,74.9,168.3,75.5L168.3,75.5z"/>
            <g>
              <path fill="#D1D3D4" d="M218.2,122.8c-14.4-40.7-53.2-68.2-96.3-69.7c-41.2-1.5-84.5,18.7-103.1,56.6
                C-0.4,149,12.5,196.3,45.5,224c33.3,27.9,80.8,31.7,119.9,14.1C210.1,218.1,233.1,169.3,218.2,122.8c-1.3-4-18.2,0.7-17.1,4.6
                c11.2,35,1.5,74.4-28,97.7c-30.7,24.2-73.7,20.2-104.6-1.6c-30.6-21.4-47.9-60.3-39.4-97.3c8.1-35.6,38.7-64.7,76-66
                c42.7-1.5,81.9,27.5,95.9,67.1C202.5,131.4,219.5,126.2,218.2,122.8z"/>
              <g>
                <path fill="#DDD1BE" d="M224.1,120.6c-19-56.7-84-86.5-143.1-70s-91.7,75.7-72.7,132.2c19,56.6,82.5,89.1,141.6,72.6
                  C209,239,243,177,224.1,120.6z M146.2,243.8c-52.5,14.7-108.6-14.3-125.4-64.3C3.9,129.4,32.8,76.7,85.3,62.3
                  c52.5-14.7,108.6,14.3,125.4,64.3C227.6,176.7,198.7,229.1,146.2,243.8z"/>
                <g>
                  <path fill="#9B8579" d="M226.1,120C194.3,28.7,53.9,18.3,11,106.1c-21.5,44-7.2,97.2,30.4,127.8c35.7,29,87.1,35.2,128.5,15
                    C217.5,225.6,242.5,170.6,226.1,120c-0.4-1.3-4.8-0.2-4.2,1.1c13.4,41.6-0.9,87.6-35.5,114.8c-38.3,30.1-93.5,29.3-133.1,2.4
                    C17.5,213.9-2.6,169.5,7.5,126.8c10.9-46.2,53.4-77.4,100.2-79.4c49.5-2.2,97.6,26.6,114.2,73.9
                    C222.4,122.4,226.7,121.3,226.1,120z"/>
                  <path fill="#9B8579" d="M146,242.8c-39,10.3-81.8-3.1-107-34.7c-24.3-30.2-28-73.1-7-105.8c23-35.9,68.7-50.4,109.2-38.7
                    c36.1,10.6,65,40.9,71.1,78.3C219.5,187.7,189.8,230,146,242.8c-2.6,0.7-2,2.8,0.6,2c38.9-11.4,69-44,71.1-85.2
                    c2-42.9-27.3-81.4-67-95.7c-37.4-13.6-81.9-5.3-110.1,23.3C10.8,117.1,6,163.5,27.7,199.4c24.5,40,74.2,57.2,118.8,45.5
                    C149,244.1,148.6,242.1,146,242.8z"/>
                </g>
              </g>
            </g>
            <g transform="translate(104.111111, 101.666667)">
              <path fill="#7C8081" d="M21.5,52.3c-2.8-4.4-9-6.2-14-4c-4.8,2.1-6.5,7.4-3.7,12c2.8,4.4,9,6.2,14,4
                C22.8,62.2,24.2,56.8,21.5,52.3z"/>
              <path fill="#BCBEC0" d="M23.5,51.5c-3.7-5.1-10.7-6.8-16.4-4.4S-1.7,55.5,1.9,61C8.9,71.9,30.9,64.5,23.5,51.5
                c-0.7-1.3-4.8,0-4.1,1.6c2.2,3.7,1.3,8.8-3.1,10.5c-3.9,1.3-8.5-0.9-10.5-4.2c-2-3.5-1.5-8.4,2.6-10.3s8.7,0.7,11,4
                C20.4,54.5,24.4,53,23.5,51.5z"/>
            </g>
            <path fill="#D1D3D4" d="M119.9,157.8c-0.4-2.4-1.8-4.2-3.5-4s-2.8,2.2-2.4,4.6s1.8,4.2,3.5,4S120.2,160.2,119.9,157.8z"/>
          </g>
          <path opacity="0.3" fill="#362B27" enableBackground="new" d="M4.6,137.3c0,0,34.2-91.1,132.9-88c0,0-41.8-14.7-85.4,18.5
            C52.1,67.7,5.7,104.9,4.6,137.3z"/>
          <g transform="translate(93.063492, 89.000000)">
            <path fill="#7C8081" d="M2.5,64.1C1.4,74.7,9.9,84.3,21.6,85.2c11.8,0.9,22.3-7,23.4-17.6s-7.4-20.2-19.1-21.1
              C14.1,45.6,3.6,53.5,2.5,64.1z"/>
            <path fill="#BCBEC0" d="M0.5,64.3C-0.8,91.6,44,93.1,46.9,67.2C50,39.7,4.9,39,0.5,64.3c-0.2,1.3,3.5,0.5,3.7-0.6
              c2.2-12.3,14.9-20,27.1-14.8C38.6,52,43.9,59.5,43,67.6C40.6,91.4,3,87,4.1,63.6C4.3,62.4,0.7,63.2,0.5,64.3z"/>
          </g>
          <g transform="translate(108.634921, 101.833333)">
            <path fill="#A7A9AC" d="M2,52c-0.4,3.1,2,5.9,5.3,6.1c3.3,0.2,6.3-2,6.6-5.1c0.4-3.1-2-5.9-5.3-6S2.4,48.9,2,52z"/>
            <path fill="#BCBEC0" d="M0.2,52.5c-0.6,9,14.7,7.9,15.7,0c1.1-9-14.4-8.1-15.7-0.2c-0.2,0.9,3.5,0.4,3.7-0.5
              c0.4-2.6,2.6-5,5.3-3.5c1.8,0.9,2.9,3.1,2.8,5.1c-0.4,2.9-2.8,5-5.5,3.3c-1.8-1.1-2.8-2.9-2.6-5.1C3.9,50.9,0.2,51.4,0.2,52.5z"/>
          </g>
          <polygon stroke="#000000" strokeWidth="0.7668" points="151.4,238.3 146.6,230.6 142.1,231.4 146.7,239.8   "/>
          <g transform="translate(40.507937, 116.500000)">
            <g>
              <path fill="#FBB040" d="M16.6,47.4c0,0,4.4,21.3,17.5,26.4c0,0-8.5,5.5-14.9,8.8C12.7,85.7-0.2,53.3,4.8,48.3L16.6,47.4z"/>
              <path fill="#F7941D" d="M14.2,48.1c2.4,10.8,7.9,22.7,18.6,27.5c0-0.9-0.2-1.8-0.2-2.8c-2.4,1.6-4.8,3.1-7.4,4.6
                c-3.5,2-5.7,4.6-8.8,1.7c-3.9-3.5-6.1-10.4-7.7-15.2C7,59,6.3,54.7,6.4,49.6c0,0.9-2.8,0.2-0.4,0.4c1.1,0,2.4-0.2,3.7-0.2
                c2.2-0.2,4.4-0.4,6.6-0.6c2.8-0.2,3.7-3.7,0.4-3.5C12.9,46.1,4.6,44.8,2,48.5c-3.3,5.1,0.9,15.2,2.9,20c2,4.9,5.2,13.8,10.9,15.8
                c6.1,2,15.1-6.2,19.9-9.3c1.3-0.7,1.1-2.2-0.2-2.8C26,67.9,21.2,56.2,19,46.7C18.4,44.7,13.6,45.8,14.2,48.1z"/>
            </g>
            <polygon opacity="0.4" fill="#ECECEB" enableBackground="new" points="17.1,52.5 9.8,71.6 13.6,79.3 21,60.2    "/>
          </g>
        </g>
        <g transform="translate(34.000000, 149.706320)">
          <line fill="none" x1="63.6" y1="112.4" x2="2" y2="46.3"/>
          <path fill="#000002" d="M65.1,112C44.6,89.9,23.9,67.9,3.5,45.7c-0.6-0.5-3.4,0.5-3,1.1C21,69,41.6,91,62.1,113.1
            C62.7,113.5,65.4,112.4,65.1,112L65.1,112z"/>
        </g>
        <g transform="translate(289.000000, 119.706320)">
          <polyline fill="#000002" points="122.1,54.9 7,157.7 1.7,151.4 118.8,47  "/>
          <path fill="#000002" d="M121.2,54.4C82.9,88.7,44.4,122.8,6.1,157.1c0.7,0,1.7,0,2.4,0C7.6,156,3,151.4,3,151.8s0,0.6,0.7-0.4
            c1.3-2.2,4.8-4.3,6.7-5.9c8.9-8,18-16,26.8-23.9C59.4,101.7,81.8,81.8,104,62c5.2-4.6,10.5-9.5,15.7-14.1c1.5-1.3-0.9-2.4-2-1.3
            C78.7,81.5,39.6,116.1,0.6,151c-0.4,0.4-0.7,0.9-0.4,1.3c1.9,2,3.7,4.3,5.4,6.3c0.6,0.6,1.9,0.6,2.4,0
            c38.3-34.3,76.8-68.4,115.1-102.8C124.7,54.2,122.3,53.3,121.2,54.4L121.2,54.4z"/>
        </g>
        <g transform="translate(378.000000, 38.706320)">
          <polyline fill="#94979B" points="6,46.8 16.4,77.5 12.6,79.2 1.6,50.5  "/>
          <path fill="#888B8C" d="M4.7,47.1c2.1,6.1,4.1,12.3,6.2,18.4c1,3.2,1.9,7.4,3.5,10.2c0.2,0.4,0.7,1.5,0.7,2.1c0-0.8,0.9-0.8-1,0.2
            c-0.7,0.2-1.4,0.6-1.9,0.9c0.7,0,1.2,0,1.9,0c-3.6-9.5-7.3-19.1-10.9-28.6c-0.3-0.8-3.1,0.2-2.9,0.8c3.6,9.5,7.3,19.1,10.9,28.6
            c0.2,0.6,1.7,0,1.9,0c1.4-0.6,2.6-1.1,4-1.7c0.2,0,1-0.4,0.9-0.8C14.3,67,10.9,56.8,7.4,46.4C7.3,45.4,4.3,46.4,4.7,47.1L4.7,47.1z
            "/>
        </g>
        <g transform="translate(338.000000, 32.706320)">
          <path fill="#6F7375" d="M40,57.1l-5.7-2.5c0,0-13.6-8.8-32.4,0.5L4.4,50c0,0,0.9-5.6,32.4-2.6"/>
          <path fill="#888B8C" d="M41.3,56.5c-4.7-1.9-9.1-4.4-14-5.4c-9-1.9-17.9,0.2-26.2,4c0.7,0,1.6,0,2.3,0c1.3-2.5,1.6-4.7,3.9-6.3
            c2.2-1.4,6.3-1.2,8.8-1.4c6.6-0.3,13.1,0.2,19.7,0.9c0.7,0,3.4-1.2,2-1.2c-9.5-0.9-22.2-2.8-31.4,1c-3.8,1.6-4.1,3.8-5.9,7.5
            c-0.4,0.7,2.2,0.2,2.3,0c14-6.3,23.7-3.2,36,2.3C39.7,57.9,42.4,56.9,41.3,56.5L41.3,56.5z"/>
        </g>
        <g transform="translate(302.000000, 33.706320)">
          <path fill="#9E5D3A" d="M40.3,49.4l-2.6,5c0,0-28.8,10.4-33.1,7.9C0.3,60,2.9,55,2.9,55l26.2-8.8"/>
          <path fill="#995C3C" d="M38.6,49.6c-0.6,1-1.1,2.1-1.5,3.1c-0.6,1.4-0.9,1.7-0.4,1.4c-1.7,1.2-4.9,1.7-6.9,2.4
            c-5.4,1.7-19.4,8.1-24.5,4.5c-1.7-1.4-2.8-3.6-1.3-5.7c-0.6,0.9-0.6,0.5,0.6,0c2.6-1.4,6-2.1,8.8-2.9c5.2-1.7,10.7-3.6,15.9-5.4
            c2.1-0.7,0.2-1.6-1.3-1.2c-7.7,2.6-15.3,5.2-23,7.8c-1.7,0.5-3.2,0.7-4.1,2.1c-2.1,2.8,0.2,7.1,3.4,7.9c6.9,1.9,18.3-3.3,24.7-5.4
            c2.6-0.9,6.9-1.6,9.2-3.1c1.9-1.4,2.6-3.8,3.6-5.7C42.2,48.4,39.2,48.6,38.6,49.6L38.6,49.6z"/>
        </g>
        <g transform="translate(194.000000, 72.706320)">
          <path fill="#94979B" d="M5.9,61.9L1.8,48.6c0,0,4-3.6,5.4-1.6c1.6,1.9,4.7,14.9,4.7,14.9"/>
          <path fill="#888B8C" d="M7.5,61.6c-0.7-2.2-1.4-4.7-2.3-6.9c-0.5-1.6-1.1-3.1-1.6-4.7c-0.2-0.5-0.3-0.9-0.3-1.4
            c-0.2-0.7-0.5,0.3,0-0.2c1.6-1.6,2.6-1,3.3,0.7s1.2,3.5,1.7,5.2c0.7,2.6,1.6,5.2,2.1,7.9c0.2,0.9,3.1,0.2,3-0.7
            c-1.1-4.3-2.3-9-3.9-13.1c-0.7-1.7-1.4-2.8-3.7-2.6C4,46,2.1,47.2,0.5,48.6c-0.2,0.2-0.3,0.3-0.3,0.5c1.4,4.5,2.8,8.8,4.2,13.3
            C4.9,63.1,7.7,62.3,7.5,61.6L7.5,61.6z"/>
        </g>
        <g transform="translate(8.000000, 143.706320)">
          <path fill="#BF3126" d="M15.2,47.5c0,0-12.8-1.2-13.3,0.3s1.2,13.8,1.2,13.8l6,0.3"/>
          <path fill="#911A1C" d="M16.1,46.8c-2.4-0.2-5-0.3-7.4-0.5c-2.1,0-6-0.5-7.8,1c-1.4,1.4-0.3,4.8-0.2,6.6c0.3,2.8,0.7,5.5,1,8.1
            c0,0.3,0.7,0.3,0.9,0.3c2.1,0.2,4,0.3,6,0.3c1,0,3.1-1.4,1.2-1.6c-2.1-0.2-4-0.3-6-0.3C4.2,61,4.4,61,4.7,61.2
            c-0.5-4.3-0.9-8.6-1.4-13c0-0.9-0.2-0.5-0.2-0.5c0.3-0.2,1.2-0.2,1.7-0.2c1.4,0,2.6,0,4,0c1.9,0,3.8,0.2,5.5,0.3
            C15.2,48.4,17.7,47,16.1,46.8L16.1,46.8z"/>
        </g>
        <g transform="translate(14.000000, 140.706320)">
          <path fill="#D7DADA" d="M3.3,62.4c0,0-0.5-13.3,4.2-15.2l11-0.6L3.3,62.4z"/>
          <path fill="#FFFFFF" d="M5.9,61.6c0-1.9,0-3.8,0.2-5.7c0.2-2.3,0.5-7.4,2.9-8.5c-1.1,0.2-2.2,0.6-3.3,0.8c3.7-0.2,7.3-0.4,11-0.6
            c-0.2-0.2-0.4-0.6-0.5-0.8C11,52.1,5.9,57.5,0.9,62.6c-0.7,0.8,0.7,0.8,1.3,0.8c1.1,0,2.7-0.4,3.5-1.3c5.1-5.3,10.3-10.6,15.2-15.8
            c0.6-0.6,0-0.8-0.5-0.8c-2.6,0.2-5,0.4-7.5,0.4c-2.8,0.2-5.5,0-7.9,1.5C0.4,50.2,0.4,58.2,0.6,63C0.6,63.9,5.9,63,5.9,61.6z"/>
        </g>
        <g transform="translate(237.000000, 196.706320)">
          <g transform="translate(1.000000, 0.000000)">
            <path fill="#10131D" d="M23.4,46.3l-16-0.2l-5.5,4c0,0,0.2,5.3,3.6,6.2c3.5,0.9,18.2,2.2,21.7-1.8C30.5,50.7,23.4,46.3,23.4,46.3z
              "/>
            <path fill="#000002" d="M24.2,45.8c-3.1,0-6,0-9.1-0.2c-2.4,0-5.3-0.5-7.7,0c-2,0.4-5.1,2.6-6.2,4S0.5,53,1.7,54.9
              c1.8,2.5,5.5,2.4,8.4,2.7c4,0.4,8.4,0.4,12.2-0.5c2.6-0.5,6-1.6,6.6-4.6c0.5-2.7-2.6-5.3-4.6-6.6c-0.5-0.4-2.7,0.7-2.4,0.9
              c1.6,1.1,3.6,2.5,4.2,4.6c1.6,5.8-8.2,5.6-11.7,5.5c-2.6,0-6,0.2-8.4-0.9c-1.5-0.7-3.8-4.4-3.5-5.6c0.5-1.5,4.6-2,4.9-3.6
              c0.2-0.4-1.8-0.2-0.5,0.2c0.7,0.2,1.8,0,2.6,0c4.2,0,8.6,0.2,12.8,0.2C23.1,46.8,25.4,45.8,24.2,45.8z"/>
          </g>
          <g transform="translate(0.000000, 3.642857)">
            <path fill="#000002" d="M27.4,47.9L2.9,46.5c0,0-4.7,7.8,15.5,8C18.4,54.5,33.7,52.9,27.4,47.9z"/>
            <path fill="#000002" d="M28.1,47.4c-8.2-0.5-16.2-1.1-24.4-1.5c-0.7,0-1.6,0.2-2,0.7c-4,6.9,10.2,8.2,13.5,8.4
              c3.3,0.2,19.9-2,13.5-7.5c-0.5-0.5-2.9,0.5-2.4,0.9c4,3.5-2.7,4.7-5.5,5.1c-3.8,0.7-8.6,0.2-12.2-0.9c-2.6-0.9-6.2-3.1-4.4-6.4
              c-0.7,0.2-1.5,0.5-2,0.7c8.2,0.5,16.2,1.1,24.4,1.5C27.2,48.7,29.4,47.6,28.1,47.4z"/>
          </g>
        </g>
        <g transform="translate(195.000000, 84.706320)">
          <path fill="#000002" d="M59.5,188.4L2.5,50c0,0,4.8-6.5,11.3-3l55.9,137"/>
          <path fill="#000002" d="M61,188.1C44.3,147.8,27.7,107.3,11,67c-1.7-4.3-7.2-12.4-7-17c0.2-3.5,6.7-3.7,8.7-0.9
            c1.7,2.2,2.4,5.9,3.5,8.5c9.4,23.1,18.9,46.4,28.3,69.5c7.8,19.2,15.7,38.3,23.5,57.5c0.4,0.9,3.3-0.2,3.1-0.7
            C53,139.7,35.1,95.5,16.9,51.3c-0.9-2.4-1.1-3.9-3.3-5c-4.3-2.2-10.2,0.9-12.8,4.3c-0.2,0.2-0.2,0.2-0.2,0.4
            c19.1,46.2,38.1,92.2,57,138.4C58.2,189.7,61.2,188.8,61,188.1L61,188.1z"/>
        </g>
        <polyline fill="#000002" stroke="#000002" strokeWidth="0.5743" points="126.9,265 206,147.2 204.5,141 122,261 "/>
        <g transform="translate(11.000000, 119.706320)">
          <path fill="#000002" d="M223.6,150.5c0,0-8.5-89-123.3-98.1c0,0-45.9-4-87.5,38.1L2.3,92c0,0,68.4-77.1,163.1-32
            c0,0,59.1,30.8,64.5,91.7"/>
          <path fill="#000002" d="M225.1,150C219,89,150.6,48.6,93.3,51.7C73,52.8,53.1,59.8,36.2,70.6c-7,4.4-13.8,9.3-19.7,15.2
            c-4.1,3.8-6.6,5.5-12.5,5.5c-0.7,0-0.6,1.1,0.7-0.2c1.3-1.3,2.8-2.7,4.1-4C44.1,53.7,94.4,38.2,142,52.2
            c44.6,13.2,81.6,53.3,86.4,99.9c0,0.7,3.1,0.2,2.9-0.7c-3.9-38.3-30.6-74-64.7-91.9c-43.5-22.9-96.4-16.7-136.9,9
            C19.5,75.1,9.1,82.8,0.8,92.2c-0.6,0.7,0.9,0.7,1.1,0.5C5,92.2,9.1,92.3,12,91.2c4.6-1.6,7.7-6.4,11.6-9.7
            C37,70.4,52.4,61.8,69.2,57c19.9-5.7,38.9-4.2,59,0.4c39.1,9.2,73,33.5,87.7,71.2c2.8,7,5.2,14.6,5.9,22.1
            C222.1,151.6,225.3,151.1,225.1,150L225.1,150z"/>
        </g>
        <g transform="translate(13.000000, 120.706320)" opacity="0.2">
          <g>
            <path fill="#FFFFFF" d="M225.1,138.1c-6.6-24-22.7-47.1-41.3-63.1c-29.2-25-69.6-33.7-106.9-26.5C47.7,54.1,21.1,68.3,0.3,89.3
              c-0.7,0.7,2.2,0.6,2.8,0c31.6-31.9,77.5-47.4,121.8-39.7c38.8,6.8,70.3,32.7,88.2,67.4c3.7,7,7,14.4,9,22
              C222.3,139.9,225.3,139,225.1,138.1L225.1,138.1z"/>
          </g>
        </g>
        <g transform="translate(246.000000, 268.706320)">
          <path fill="#000002" d="M8.5,48.9l18.6,65l7.4,7l-10.2,0.9l-0.9-7L2.5,47.6C2.7,47.6,5.5,44.7,8.5,48.9z"/>
          <path fill="#000002" d="M6.8,49.3c4,13.8,7.8,27.4,11.7,41.2c2.1,7,3.6,14.3,6.1,21.1c1.5,4.4,4.7,6.6,8.3,9.9
            c0.6-0.6,1.1-1.1,1.7-1.7c-1.7,0.2-3.4,0.4-5.1,0.4c-0.9,0-3,0.6-3.8,0.4c-1.7-0.6,0,0.2,0,0.2c-1.1-1.5-0.6-5.3-1.1-7.3
            c-1.7-7-4.4-14-6.4-20.8c-3-9.7-6.1-19.7-9.1-29.4C8,59.4,6.8,55.7,5.5,51.8C4.7,49.3,2.1,44.7,6.8,49.3c0.8,0.9,3.8-0.4,2.8-1.3
            c-2.6-2.6-5.4-3.5-8.5-1c-0.2,0.2-0.4,0.6-0.4,0.7c4,12.9,8,25.9,11.9,38.8c3.6,11.8,8.1,23.3,9.7,35.5c0,0.6,0.9,0.6,1.3,0.6
            c3.4-0.4,6.8-0.6,10.2-0.9c0.6,0,2.5-0.7,1.7-1.7c-3.8-3.5-7-5.5-8.3-10.3c-2.1-7.3-4.2-14.7-6.3-22c-3.8-13.2-7.6-26.3-11.4-39.5
            C9.7,47.2,6.6,48.2,6.8,49.3z"/>
        </g>
        <g transform="translate(247.000000, 279.706320)">
          <path fill="#000002" d="M7.3,48.2l17.6,64.9l7,7l-9.7,0.9l-0.9-7L1.5,46.9C1.7,46.9,4.4,44,7.3,48.2z"/>
          <path fill="#000002" d="M5.8,48.6C9.6,62.3,13.2,75.9,17,89.7c2,7,3.4,14.3,5.8,21.1c1.4,4.4,4.5,6.6,7.9,9.9
            c0.7-0.4,1.4-0.9,2-1.3c-2.2,0.2-8.5,1.1-8.8,0.7c-1.1-1.1-0.5-5.5-1.1-7.3c-1.6-7-4.1-13.9-6.1-20.7c-2.9-9.7-5.8-19.6-8.6-29.3
            C7,59,5.9,55.3,4.7,51.4C3.9,49,1.3,44.2,5.8,48.6c0.7,0.7,3.4-0.4,2.9-0.9c-2.3-2.4-5.2-3.3-8.1-0.9c-0.2,0.2-0.4,0.4-0.4,0.5
            C4,60.1,7.8,73.2,11.8,86c3.4,11.7,7.7,23.3,9.2,35.4c0,0.4,0.7,0.4,0.9,0.4c3.2-0.4,6.5-0.5,9.7-0.9c0.4,0,2.7-0.6,2-1.3
            c-3.6-3.5-6.7-5.5-7.9-10.3c-2-7.3-4-14.7-5.9-22C16.2,74.1,12.6,61.1,9,47.9C8.5,46.9,5.5,47.9,5.8,48.6z"/>
        </g>
        <g transform="translate(104.000000, 227.706320)">
          <path fill="#000002" d="M126.7,62.8L3.4,46.1c0,0-2,3.4-1.3,6.9c0.7,3.4,122.5,17.9,122.5,17.9L126.7,62.8z"/>
          <path fill="#000002" d="M127.6,62.1C86.5,56.6,45.5,51,4.4,45.6c-0.9-0.2-2,0.2-2.4,0.9c-5,9.6,4,9.2,11.2,10.5
            c14,2.5,28.2,4.3,42.4,6.3c22.7,3.1,45.6,6,68.3,8.7c0.7,0,2.2-0.2,2.4-0.9c0.7-2.7,1.3-5.6,2-8.3c0.2-1.1-2.8-0.5-3.1,0.4
            c-0.7,2.7-1.3,5.6-2,8.3c0.7-0.4,1.7-0.7,2.4-0.9C105.3,68.2,85,65.7,64.5,63c-14-1.8-28-3.8-42.1-6c-4.6-0.7-9-1.5-13.7-2.4
            c-5.2-1.1-7.6-1.5-4.1-8.2c-0.7,0.4-1.7,0.7-2.4,0.9c41.1,5.4,82.1,11.1,123.3,16.5C126.7,63.7,129.3,62.2,127.6,62.1z"/>
        </g>
        <path opacity="0.4" fill="#FFFFFF" stroke="#000002" strokeWidth="0.5743" enableBackground="new" d="M110,277.6
          c0,1.3,1.1,2.4,2.6,2.4c1.3,0,2.4-1.1,2.4-2.6c0-1.3-1.1-2.4-2.6-2.4C111.1,275.2,110,276.3,110,277.6z"/>
        <g transform="translate(163.000000, 117.706320)">
          <path fill="#282E33" d="M28.4,45.9c0,0,8.6,3.5,8.6,7.5s-4.3,9.1-4.3,9.1l-7.5,3.1L10.9,94.9l-8.9-4L28.4,45.9z"/>
          <path fill="#000002" d="M27,46.5c6.9,2.9,12.1,10.1,4.5,16.1c-2.6,2-6.1,1.8-8.2,4.4c-3.5,4.2-5.4,11.1-7.8,16.1c-2,4-3.9,8-6,12.2
            c1.1-0.2,2.1-0.5,3.2-0.7c-2.1-0.9-7.1-2-8-3.5c-0.9-1.3-0.2-0.2,0.4-2.2c1.1-4.8,6.3-10.8,8.8-15c5.4-9.3,11-18.6,16.4-28
            C30.3,45.8,27.3,45.8,27,46.5c-8.8,15-17.7,30-26.5,45c0,0.2,0,0.2,0,0.2c3,1.3,6,2.6,8.9,4c0.7,0.4,2.8-0.2,3.2-0.7
            c2.2-4.6,4.5-9.1,6.7-13.9c2-4.4,4.1-11.9,7.5-15.4c2.6-2.6,7.1-1.8,9.7-6.2c4.1-6.9,0.4-10.8-6.5-13.7C29.2,45,26.8,46.5,27,46.5z
            "/>
        </g>
        <path d="M207.4,110.5c-4.1,4-6.7,7.4-10.6,8.2c-6.2,1-11.2,0.6-17.3-0.4c-9.9-1.4-19.4-4.8-28.9-7.4c-1.3-0.4-3.7,1.8-1.9,2.2
          c11,3,22,6.6,33.2,8.2c5.6,0.8,11.2,1.2,16.8-0.4c4.5-1.2,7.5-5.4,10.8-8.8C211.1,110.5,208.5,109.3,207.4,110.5L207.4,110.5z"/>
        <g transform="translate(150.000000, 66.706320)">
          <path d="M2.4,46.3c0,0,2.2,8.7,5.5,9.3c3.1,0.5,11-0.2,11-0.2l0.4-4.5L2.4,46.3z"/>
          <path d="M0.7,46.7c0.7,2.9,1.8,7.5,4.8,9.1c1.7,0.9,4.4,0.7,6.2,0.5c2.2,0,4.2-0.2,6.4-0.3c0.6,0,2-0.2,2.2-0.9
            c0.2-1.5,0.4-2.9,0.4-4.5c0-0.2,0-0.3-0.4-0.5C14.7,48.6,9,47,3.5,45.5c-0.9-0.2-3.5,1-2.4,1.2c3.8,1,7.7,2.1,11.5,3.1
            c1.5,0.3,3.9,0.5,5.1,1.4c0.4,0.3,0-0.2,0-0.2c0.2,0.2,0,1-0.2,1.2c-0.2,1-0.2,2.1-0.4,3.1c0.7-0.3,1.5-0.7,2.2-0.9
            c-3.1,0.2-9.2,1.5-11.5-0.3c-2.4-1.7-3.3-5.8-4-8.4C3.7,45.1,0.6,45.8,0.7,46.7z"/>
        </g>
        <g transform="translate(166.000000, 72.706320)">
          <path d="M2.1,47.7l20.4-1c0,0-2,5.8-0.4,7s5.3-0.6,5.3-0.6l-1.1-5.8"/>
          <path d="M1.4,48.5c6.7-0.4,13.7-0.6,20.4-1c-0.4-0.2-0.5-0.4-0.9-0.6c-0.5,1.8-2,5.8-0.2,7.4c1.8,1.6,5.5,0,7.1-0.8
            c0.4-0.2,1.1-0.4,0.9-1c-0.4-2-0.7-3.8-1.1-5.8c-0.2-1-3.3-0.2-3.1,0.8c0.4,2,0.7,3.8,1.1,5.8c0.4-0.4,0.5-0.6,0.9-1
            c-5.5,2.6-3.5-3.4-2.7-6c0.2-0.6-0.5-0.6-0.9-0.6c-6.7,0.4-13.7,0.6-20.4,1C1.7,46.9-0.6,48.7,1.4,48.5L1.4,48.5z"/>
        </g>
        <g transform="translate(143.000000, 37.706320)">
          <path fill="#BE7348" d="M87.9,67.8l-18.7,3.6L63,74.3l-9.3,4c0,0-9.3,2.1-28.4-1.9C18.9,75,11.5,73,2.7,70.1c0,0-6-17.8,25.7-23.3
            c0,0,10.9,0.9,12.6,1.3c1.6,0.4,6.9,4.7,13.1,6.3c6.4,1.5,25.1,1.1,25.1,1.1S94.5,59.1,87.9,67.8z"/>
          <path fill="#995C3C" d="M88.1,67.1c-6.2,1.1-12.6,1.9-18.6,3.6c-4.9,1.5-9.5,4.4-14.2,6.1c-6,2.3-15.1,1.1-22.6,0
            c-8.4-1.3-18-2.8-25.7-6.4c-3.8-1.7-2.7,0-3.3-3.4c-2.2-10.4,12-16.7,18.9-18.6c4.5-1.3,9.3-1.1,13.8-0.2c4.4,0.8,7.3,3.2,11.3,4.9
            c8,3.6,17.7,3,26.2,3c5.6,0,17.8,2.7,12.4,11.6c-0.7,1.1,2.4,0.8,2.7,0C97.6,54,73.9,54.8,67.5,54.6c-6-0.2-11.6-0.4-17.3-2.8
            c-3.6-1.5-6.4-4-10.2-4.9c-7.3-1.7-14.4-0.9-21.5,1.5c-6,2.1-12.6,5.1-15.8,11.2c-1.3,2.3-3.3,8-1.6,10.6c1.3,2.1,5.8,2.5,8.2,3.2
            c6,1.9,12.2,3.4,18.6,4.5c7.8,1.3,17.3,3.2,25.1,1.3c5.3-1.1,10.2-4.5,15.1-6.4c5.6-2.1,13.1-2.8,19.5-4.2
            C89,68.4,90.3,66.7,88.1,67.1z"/>
        </g>
        <path opacity="0.5" fill="#995C3C" enableBackground="new" d="M230,98.3c0,0-12.7-0.4-15.3,0.4c-2.6,0.6-17.4,9.3-21,9.3
          s-43-8.2-44.3-13c0,0-4.6,10.7-3,12.8l30.3,8.4l18.6,0.8l19-7.6l19-3.7C233.3,105.5,236.3,98.9,230,98.3z"/>
        <g transform="translate(148.000000, 37.706320)" opacity="0.4">
          <g>
            <path fill="#FFFFFF" d="M82.3,59.8c-7.7-6.2-12.5-5.5-22-5.3c-8.6,0.2-14.7-0.9-21.7-5.9c-5.8-4-7.5-3.4-14.6-2.1
              c-7.7,1.5-19,3.4-23.1,11c-0.7,1.5,2.2,1.5,3,0.4c3.5-6.6,20.3-11,27.4-10.2c0.4,0,1.9,0.2,0.4-0.2c1.3,0.4,3.4,2.3,4.5,3
              c2.1,1.3,4.3,3.4,6.5,4.3c4.1,1.7,9.5,2.1,13.8,2.1c9.3-0.2,15.7-1.5,23.5,4.7C81,62.7,83.8,61,82.3,59.8L82.3,59.8z"/>
          </g>
        </g>
        <path opacity="0.3" fill="#FFFFFF" enableBackground="new" d="M160,92.3c0,3.3,6.5,5.9,14.6,5.7c8-0.2,14.4-2.9,14.4-6.3
          s-6.5-5.9-14.6-5.7C166.4,86.4,159.8,89.2,160,92.3z"/>
        <g transform="translate(357.000000, 16.706320)">
          <path fill="#94979B" d="M2.2,54.4c0,0,19.8-3.7,21.5-2.1c1.7,1.5,9.9,8.7,9.9,8.7l-10.1,1.4l2.8,9.9l8-1.7c0,0,5.2-2.9,6.4-10.1
            c0.9-7.2-13.6-13.1-16.8-14.1c-3.2-1.1-21,2.6-21,2.6"/>
          <path fill="#888B8C" d="M2,55.3c5-1,10.3-1.9,15.3-2.3c6.4-0.6,9.9,4.6,14.6,8.7c0.6-0.6,1.1-1,1.7-1.5c-3.4,0.4-6.7,0.8-10.1,1.4
            c-0.6,0-2.1,0.6-1.7,1.4c0.9,3.3,1.9,6.6,2.8,9.9c0.2,0.8,1.3,0.6,1.7,0.4c4.1-1,8.2-1.2,11.4-4.3c6-6,5.2-13-1.3-17.6
            c-11.2-8.3-21.3-6-34-3.3c-1.9,0.4-1.5,2.1,0.4,1.7c9.7-1.9,18.1-4.3,27.3,0.8C37,54.5,43,61.8,34.8,69c-1.9,1.5-5.6,1.9-8.8,2.7
            c0.6,0.2,1.1,0.2,1.7,0.4c-0.9-3.3-1.9-6.6-2.8-9.9c-0.6,0.4-1.1,1-1.7,1.4c3.4-0.4,6.7-0.8,10.1-1.4c0.4,0,2.4-0.8,1.7-1.5
            c-3.7-3.3-7.9-9.1-13.1-9.5c-6.4-0.4-13.3,1.4-19.4,2.5C0.9,53.8-0.1,55.7,2,55.3L2,55.3z"/>
        </g>
        <g transform="translate(373.000000, 31.706320)">
          <path fill="#94979B" d="M6.9,47.7c0,0,3.1,8.1,2.6,9.6C8.9,59,5,57.9,5,57.9s-3.1-8.7-3.1-10.2C1.9,46.2,5.3,46.2,6.9,47.7z"/>
          <path fill="#888B8C" d="M5.5,48.3c0.7,1.9,1.4,4,2.1,5.9c0.2,0.6,1,2.5,0.7,3.2c0,0-0.7,0-1.2,0c-0.3,0-0.9-0.2-1-0.2
            c0.2,0.2,0.3,0.4,0.5,0.6c-0.7-1.9-1.4-3.8-1.9-5.5c-0.3-1.1-0.7-2.3-1-3.6c-0.2-0.4-0.2-0.8,0-0.9c0.5-0.4,1.9,0.6,2.2,0.9
            c0.9,0.8,3.4-0.6,2.2-1.7c-2.2-2.1-8.2-2.1-7.2,2.5c0.5,1.9,1.2,4,1.7,5.9c0.3,0.9,0.7,2.8,1.2,3.6c0.7,0.9,3.1,0.8,4.1,0.8
            C9.4,59.6,11,58.9,11,57c0-3-1.5-6.6-2.7-9.4C7.7,46,5,46.9,5.5,48.3z"/>
        </g>
        <g transform="translate(386.000000, 67.706320)">
          <path fill="#94979B" d="M3.4,48.8l7.6-2.3l1.7,6.4c0,0,2.1,0,2.3,2.1c0,2.1-10.8,6.2-12,3.4c-1.1-2.6-1.1-2.6-1.1-2.6L3.4,48.8z"/>
          <path fill="#888B8C" d="M3.6,50c2.5-0.8,5.1-1.5,7.6-2.3c-0.6-0.2-1.3-0.6-1.9-0.8c0.6,2.1,1.1,4.3,1.7,6.4
            c0.2,0.6,0.9,0.8,1.5,0.8c0.8,0,0.8,0.9,0.2,1.7c-0.8,0.8-2.1,1.1-3,1.5c-1.1,0.4-2.3,0.8-3.4,0.9c-1.3,0.2-1.5,0-2.1-1.1
            c-0.8-1.9-0.6-1.1-0.2-3c0.4-1.7,0.8-3.6,1.1-5.3S2.2,47.5,1.9,49c-0.4,1.3-0.6,2.6-1,4c-0.2,0.9-0.8,2.3-0.6,3.4
            c0.4,3,2.5,4.5,5.3,4.3c3.2-0.2,7.4-1.5,9.7-3.8c2.5-2.3,0.4-5.1-2.5-5.1c0.6,0.2,0.9,0.6,1.5,0.8c-0.6-2.1-1.1-4.3-1.7-6.4
            c-0.2-0.8-1.3-0.9-1.9-0.8c-2.5,0.8-5.1,1.5-7.6,2.3C1.1,48.3,1.7,50.5,3.6,50z"/>
        </g>
        <g transform="translate(388.000000, 74.706320)">
          <line fill="none" x1="11.6" y1="47.1" x2="1.6" y2="49.9"/>
          <path fill="#888B8C" d="M11.2,46c-3.3,0.9-6.7,1.8-10,2.6c-1.8,0.5-1.2,2.6,0.5,2.1c3.3-0.9,6.6-1.8,10-2.6
            C13.7,47.6,13.2,45.7,11.2,46L11.2,46z"/>
        </g>
        <path opacity="0.4" fill="#4F5255" enableBackground="new" d="M397,126.4l-2.7-10.1l-2.7-3.7l-8.4-23.3l-3.8-4.7
          c0,0-3.8-5.8-4.2-2.6c-0.5,3.2,14.8,36.2,14.8,36.2s1.8,9.7,3.3,10.6C395,129.8,397,126.4,397,126.4z"/>
        <path opacity="0.3" fill="#4F5255" enableBackground="new" d="M380,84c0,0,11.6-1.3,12-5.8c0.3-4.4-6.4-4.2-6.4-4.2l-1.3,5
          l-6.2,0.2"/>
        <g transform="translate(364.000000, 21.706320)" opacity="0.2">
          <g>
            <path fill="#FFFFFF" d="M31.4,95.9c-2.7-7.9-5.3-15.7-8-23.8c-0.4-0.9-3-9.5-3.8-8c0.8-1.6,8.3-3.7,10.4-4.9
              c9.7-6.4-0.8-10.8-8.1-12.8c-6.4-1.8-13.1-0.2-19.5,0.4c-0.9,0-3.4,1.5-1.3,1.3c5.9-0.5,12.1-1.8,17.6-0.5
              c4,0.9,13.6,3.3,12.5,8.6c-0.8,3.7-9.3,6.4-12.7,7.5c-0.2,0-1.1,0.4-0.9,0.7c3.6,10.8,7.4,21.6,11,32.3
              C28.6,97.6,31.6,96.6,31.4,95.9L31.4,95.9z"/>
          </g>
        </g>
        <g transform="translate(387.000000, 76.706320)">
          <path fill="#000002" d="M13.8,46.8L1.9,50.1c3.2,4,6.3,7.9,8.3,12.5c2.3,4.8,4,9.9,6.1,14.9c2.7,6.8,5.5,13.4,8.1,20.2
            c0,0.2,1.3,3.3,1.3,3.3l7-2.8L13.8,46.8z"/>
          <path fill="#000002" d="M13.6,45.8c-4,1.1-8,2.2-11.9,3.3c-0.6,0.2-1.7,0.7-1.1,1.5c11.6,14.7,16.9,33.5,23.7,50.6
            c0.4,0.9,1.5,0.6,2.3,0.4c2.3-0.9,4.7-1.8,7-2.8c0.4-0.2,1.1-0.7,0.9-1.1c-6.3-17.1-12.7-34.2-18.9-51.5c-0.4-1.3-3.6-0.4-3.2,0.7
            c3.6,9.9,7.4,20,11,30c2.1,5.7,4.2,11.4,6.3,17.1c0.2,0.7,1.7,3.1,1.5,4c0.2-0.9,0.2-0.6-1.7,0.2c-1.5,0.6-2.8,1.1-4.4,1.7
            c0.8,0.2,1.5,0.2,2.3,0.4C20.5,83,15.2,64,3.4,49.2c-0.4,0.6-0.8,1.1-1.1,1.5c4-1.1,8-2.2,11.9-3.3C16.1,47.1,15.5,45.3,13.6,45.8z
            "/>
        </g>
        <g transform="translate(361.000000, 123.706320)">
          <path fill="#000002" d="M18.6,198.5l-8.7,7.4c0,0-5.7-18.3-7.4-26.3s-9.8-68.2,57.8-115.8c0,0,15.5-10.6,84.9-16.7l7.4,10.6
            c0,0-47.4-9.3-102,24.8c0,0-43.4,29.5-36.4,93.7C14.4,176.2,17,195.3,18.6,198.5z"/>
          <path fill="#000002" d="M17.3,198.1c-3,2.4-5.7,5-8.7,7.4c0.9,0,1.8-0.2,2.8-0.2c-3.5-11.3-7.8-23-8.5-35
            C2,154.6,5.1,138.6,11,124.2c9.6-23.5,28.2-47,50.2-60c6.6-3.9,14.9-5.7,22.3-7.4c12.5-3,25.5-4.8,38.2-6.5
            c5.7-0.7,18.8-4.3,22.9-1.3c2.6,1.9,4.6,6.5,6.5,9.1c0.7-0.6,1.7-0.9,2.4-1.5c-31.6-5.9-67.4,4.8-94.7,19.8
            C35.6,89.2,20,112.5,14.5,138.5c-2.8,12.4-3.3,25.4-1.8,38c0.9,7.2,1.5,15.6,4.2,22.2c0.4,1.1,3.5,0,3.1-0.7
            c-2.8-6.7-3.5-15-4.2-22.2c-1.7-14.1-0.7-28.5,3-42c4.6-17.4,14-33.9,27.5-46.3C61.8,73.2,84.9,65.3,105.2,61
            c14.9-3.3,31.6-5.2,46.9-2.4c0.6,0.2,3.1-0.4,2.4-1.5c-1.8-2.8-3.7-6.1-6.1-8.5c-3-3-2.2-2.2-7.9-1.7c-15.1,1.5-30.4,3.3-45.4,6.1
            c-11.3,2-23.8,4.3-34,9.6C43.4,72.2,27.3,90.7,17,107.7C6.8,124.4,0.7,143.5,0.2,162.9c-0.4,15,4.1,28.7,8.5,43.2
            c0.4,0.9,2.2,0.2,2.8-0.2c3-2.4,5.7-5,8.7-7.4C21.2,197.6,18.1,197.6,17.3,198.1z"/>
        </g>
        <g transform="translate(412.000000, 128.706320)">
          <path fill="#000002" d="M8.7,46.6l6.4,10l23.1,54l10.2,15.8l32,28.8c0,0,4.8,5.8,1.1,6.3c-3.7,0.6-5.9-2.6-5.9-2.6l-37.9-35.7
            L3.6,57.7c0,0-4.8-1.5-1.6-8.5"/>
          <path fill="#000002" d="M7.2,47c10.6,16.3,17.2,34.9,24.7,52.9c2.9,6.9,5.9,12.6,9.9,18.9c4.6,6.9,8.6,11,14.8,16.5
            c7.3,6.5,16.1,12.6,22.5,20.1c3.1,3.7,1.5,4.6-2.2,2.4c-3.7-2.4-7-6.5-10.1-9.5c-8.4-7.8-17.4-15.4-25.3-23.8
            c-3.7-3.9-5.7-9.1-8.2-13.9C26.8,98,20.2,85.4,13.6,72.8c-2.2-4.3-4.2-8.9-6.8-13c-2.3-3.9-4.7-5.6-3-10.8c0.2-0.6-2.7-0.2-2.9,0.6
            c-1.6,5-0.4,5.9,2.2,9.5c2.2,3.2,3.8,7.2,5.5,10.6c6.4,12.3,13,24.7,19.4,37c2.9,5.6,5.5,12.6,9.5,17.6c7,8.4,16.5,15.4,24.3,22.8
            c3.8,3.5,13.4,16.3,19.8,14.9c7.9-1.7-0.2-8.5-3.1-11.1c-14.5-13-29.6-24.1-38.6-42C29.4,88,23.3,65.4,10.7,45.9
            C9.8,45.5,7.1,46.8,7.2,47L7.2,47z"/>
        </g>
        <g transform="translate(361.000000, 236.706320)">
          <polyline fill="#000002" points="128.2,50.3 2.2,55.1 2,52.1 123.4,46.9  "/>
          <path fill="#000002" d="M128.4,49.1c-36.2,1.4-72.5,2.8-108.7,4.2C14.6,53.5,8.1,54.9,3,53.9c-0.2,0-0.4,0-0.6,0
            c3.1,0.2-0.2,0.6,1.3-1.2c0,0-2.6,1.2-0.9,0.4s5-0.2,6.8-0.2c20.5-0.8,41-1.8,61.4-2.6c17.5-0.8,34.9-1.6,52.4-2.2
            c1.7,0,2.4-2.4,0.4-2.2C83.4,47.7,43,49.3,2.6,51.1c-0.6,0-1.7,0.6-1.7,1.2c0,1,0,2,0,2.8s0.7,1,1.5,1c42.1-1.6,84-3.2,126-4.8
            C129.7,51.3,130.4,49.1,128.4,49.1L128.4,49.1z"/>
        </g>
        <g transform="translate(401.000000, 81.706320)">
          <polyline fill="#000002" points="9.6,63.1 9.6,46.5 1.9,51.7 1.5,56.2  "/>
          <path fill="#000002" d="M11,62.7c0-5.5,0-11.1,0-16.8c0-0.7-2.2,0.2-2.6,0.3c-2.4,1.6-5,3.1-7,4.8c-1.5,1.2-1,3.5-1.2,5.4
            c0,0.5,2.7,0.2,2.9-0.7c0.2-1.2,0.2-2.4,0.3-3.8c0.2-0.9-0.2-0.5,0-0.5c0.2-0.3,0.9-0.5,1.2-0.7c2.1-1.4,4.3-2.9,6.3-4.3
            c-0.9,0.2-1.7,0.2-2.6,0.3c0,5.5,0,11.1,0,16.8C8.2,63.9,11,63.6,11,62.7L11,62.7z"/>
        </g>
        <path opacity="0.3" fill="#362B27" enableBackground="new" d="M375,270c0,0,34.5-90.8,134-87.8c0,0-42.1-14.5-86.1,18.5
          C422.9,200.6,376.3,237.7,375,270z"/>
        <line opacity="0.5" fill="none" stroke="#FFFFFF" strokeWidth="0.5743" enableBackground="new" x1="409" y1="170" x2="299" y2="268"/>
        <g transform="translate(197.000000, 90.706320)" opacity="0.3">
          <g>
            <path fill="#FFFFFF" d="M0.8,46.6C17.9,88.4,35,130.2,52.1,172.1c0.4,0.9,3.3,0,3.1-0.7C38.2,129.5,21.1,87.7,4,45.8
              C3.6,44.9,0.7,45.8,0.8,46.6L0.8,46.6z"/>
          </g>
        </g>
        <path fill="#7F8286" stroke="#888B8C" strokeWidth="0.5743" d="M372.7,67.4c0,0,0.8,4.9-0.4,5.6s-2,1-2,1l-1.2-1l-1.2-6L372.7,67.4
          z"/>
        <g transform="translate(359.000000, 0.706320)">
          <path fill="#EF3934" d="M2.3,56.1c0,5.5,5.3,10,11.8,10s11.8-4.4,11.8-10s-5.3-10-11.8-10S2.3,50.6,2.3,56.1z"/>
          <path fill="#A81E22" d="M0.6,56.5c0.9,14.2,26.6,13.1,26.6-0.7C27.3,41.3,1.7,42.8,0.6,56.5c0,0.6,2.9,0.2,3.1-0.7
            c0.9-12,20.7-10.9,20.7,0.7C24.2,68.7,4.6,67.2,3.7,55.7C3.7,55,0.6,55.6,0.6,56.5z"/>
        </g>
        <path d="M365.7,58.3c0,0-7.3-2.7-7.7-0.4c-0.4,2.3,1.9,4.1,1.9,4.1l8.1-2.7"/>
        <path opacity="0.2" fill="#FFFFFF" enableBackground="new" d="M378,52.5c0,1.3-2.3,2.5-5,2.5s-5-1.2-5-2.5s2.3-2.5,5-2.5
          S378,51,378,52.5z"/>
        <path opacity="0.5" fill="#FFFFFF" enableBackground="new" d="M378,52.5c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5
          s1.1-2.5,2.5-2.5C376.8,50,378,51.1,378,52.5z"/>
        <path fill="none" stroke="#000000" strokeWidth="0.6154" d="M387,57.9c0,0-16.4,3.1-24-0.9"/>
        <g transform="translate(332.000000, 26.706320)">
          <path fill="#646869" d="M31.8,46.2c0,0,4.5,9.7,2.7,12.6C32.8,61.7,4.7,74.3,2,71.4s0.9-4.8,0.9-4.8l25.3-8.7l1.6-8.3L31.8,46.2z"
            />
          <path fill="#565959" d="M30.9,46.2c1.4,2.9,3.3,7,3.1,10.4c-0.2,3.1-3.1,4.1-5.2,5.2c-7.1,3.9-15,7.5-22.8,9.1
            c-2.4,0.6-6.2-1-2.9-3.9c0.4-0.4,0.9-0.4,1.4-0.6c2.7-1.2,5.6-1.9,8.3-2.9c5.2-1.7,10.7-3.7,15.9-5.4c0.2,0,0.2-0.2,0.4-0.2
            c0.9-4.3,1.3-7.7,3.6-11.4C33.1,46.2,31.3,45.8,30.9,46.2c-1.8,2.9-2.4,4.6-2.9,7.9c-0.7,3.9-0.2,3.3-3.6,4.6
            c-7,2.6-14.4,4.3-21.3,7.4c-1.6,0.8-3.6,2.1-2.5,4.4c0.9,1.9,3.1,1.9,4.7,1.7c8-1,16.1-5,23.2-8.7c2.2-1.2,6.3-2.5,7.4-5
            c1.4-3.5-1.6-9.3-2.9-12.2C32.4,45.6,30.8,45.8,30.9,46.2z"/>
        </g>
        <path d="M364.4,73.4c4.8-1.7,12.3-5,17.3-4.3c8,1.1,6.9,18.1,7.3,24.1c0.4,5.8,2.1,11.8,4.8,16.8c2.6,4.9,10.1,9.7,6.3,16.1
          c-0.7,1.3,2.1,1.1,2.6,0.2c3.9-6.7-3-11.4-6-16.4c-4.8-8-4.7-16.8-5.6-25.6c-0.6-5.4-0.2-15.7-6.7-17c-6.2-1.1-14.7,2.6-20.3,4.5
          C362.4,72.1,362.8,73.7,364.4,73.4L364.4,73.4z"/>
        <g transform="translate(319.000000, 19.706320)">
          <path fill="#BE7348" d="M40.4,46.6c0,0,6.5,7.8,3.9,11.1C41.6,60.9,6.8,70.2,3.6,68.1c-3.1-2-0.7-11.1-0.2-13.9
            C3.8,51.6,40.4,46.6,40.4,46.6z"/>
          <path fill="#995C3C" d="M38.9,47.3c2.6,3.3,6.1,8.2,2,11.3c-2.8,2.2-8.1,3-11.3,3.9c-4.6,1.3-9.2,2.6-13.8,3.5
            c-4.2,1-10.9,3.6-12.2-2.1c-0.9-4.6,0.6-9.3,4.8-10.8s9-1.9,13.5-2.8c6.1-1.1,12.2-2,18.3-2.8c1.5-0.2,2.4-2.2,0.4-1.9
            C29.9,47.1,19,48.4,8.7,51.2c-2.4,0.6-5.2,1.1-6.6,3.2c-2,3-2.8,12.8,1.1,14.7c3,1.5,8.9-0.6,11.8-1.1c7-1.5,14-3.2,20.7-5.4
            c3-0.9,8.1-1.9,10-4.5c2.8-3.7-1.5-9.3-3.7-12.1C40.9,45.1,38,46.2,38.9,47.3z"/>
        </g>
        <path opacity="0.5" fill="#995C3C" enableBackground="new" d="M359.2,69.6L327,77l0.7,9c0,0,30.8-5.1,35.3-9.8
          C363,76.2,362.8,66.6,359.2,69.6z"/>
        <path opacity="0.3" fill="#FFFFFF" enableBackground="new" d="M323,78c0,3.3,1.2,6,2.6,6s2.4-2.7,2.4-6s-1.2-6-2.6-6
          S323,74.7,323,78z"/>
        <line fill="none" stroke="#000002" strokeWidth="0.5986" x1="172" y1="206" x2="184" y2="182"/>
        <g transform="translate(179.000000, 133.706320)">
          <path fill="#BF3126" d="M7.5,45.8c0,0,2.2-1.2,2.2,2.3S4,57.4,4,57.4l-2.9-3.5L7.5,45.8z"/>
          <path fill="#911A1C" d="M8.2,46c0.9-0.4,0.7,2.8,0.7,3.3c-0.3,1.2-1,2.5-1.7,3.5c-1,1.6-2.4,3.2-3.8,4.6c0.5,0,1,0,1.5,0
            c-0.7-0.9-2.1-2.1-2.6-3.2c-0.3-0.7-0.2,0.2,0-0.5c0.2-0.5,0.7-1,1-1.4c1.7-2.1,3.3-4.4,5-6.5c0.2-0.3-1.4-0.5-1.7,0
            c-2.1,2.8-4.1,5.4-6.3,8.2v0.2c1,1.2,1.9,2.3,2.9,3.5c0.3,0.3,1.2,0.2,1.5,0c2.2-2.3,5-5.4,5.7-8.6c0.5-2.6-1.2-4.5-3.6-3.3
            C6.2,46,7.9,46.3,8.2,46z"/>
        </g>
        <g transform="translate(85.000000, 210.706320)">
          <path fillRule="evenodd" clipRule="evenodd" fill="#10131D" d="M3.6,69.4c6.6,18.9,154.1,49.9,173,50.2s25.3-1.8,25.3-1.8
            c39.4-33-0.4-62.5-0.4-62.5S27.1,45.8,18.6,45.9C10.2,46.1-3,50.5,3.6,69.4z M18.3,64.8c-0.2-14.7,126.6,6.1,126.6,6.1l-2.2,29.5
            C142.7,100.6,18.6,79.7,18.3,64.8z"/>
          <g>
            <path fillRule="evenodd" clipRule="evenodd" fill="#000002" d="M2.1,69.8c4,9.9,23.1,14.5,31.9,17.6c20.9,7.3,42.6,13,64,18.2
              c20.2,4.8,40.4,9.2,60.7,12.6c5.3,0.9,10.6,1.8,16.1,2.2c7.7,0.4,19.3,1.3,26.8-1.8c8.6-3.7,16.3-16.1,18.3-25.1
              c2.2-9.9-1.1-19.6-6.8-27.7c-2.4-3.5-6.6-9.5-10.8-11c-2-0.7-5.1-0.4-7.2-0.4c-38-2-75.8-4-113.8-6c-18.3-0.9-36.9-2-55.2-2.8
              C10,44.7-3.9,50.5,2.1,69.8c0.4,0.9,3.3,0,3.1-0.7C1.4,56.8,5.6,46.9,19.5,46.9c4,0,7.9,0.4,11.9,0.5c15,0.7,30.3,1.5,45.3,2.2
              c35.8,1.8,71.6,3.7,107.1,5.7c4.4,0.2,9,0.5,13.4,0.7c3.3,0.2,2.6,0,4,1.1c17.1,13.2,21.5,35.9,7.2,52.8
              c-3.9,4.6-6.2,7.5-11.9,8.2c-6.6,0.9-13.6,1.1-20.2,0.7c-4.8-0.4-9.5-1.1-14.3-1.8c-17.6-2.9-35-6.6-52.5-10.6
              c-22.4-5.1-45-10.8-66.8-18c-8.4-2.8-16.9-5.7-25-9.7c-4.2-2-10.8-4.8-12.8-9.5C4.7,67.9,1.8,69,2.1,69.8z"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#000002" d="M19.7,64.6c0.7-11.6,79.6,0.7,88.6,1.8c7.2,0.9,14.3,2,21.5,3.1
              c3.7,0.6,9,0.4,12.5,2c1.8,0.9,0.7-0.4,0.9,0.2c0.7,1.8-0.6,6.1-0.6,8.2c-0.6,7-1.1,13.9-1.7,20.7c0.7-0.4,1.7-0.7,2.4-0.9
              C123.5,96.4,103.7,92.4,84,88c-14.5-3.2-29-6.7-43.4-11.4C35.6,74.9,20.2,71.3,19.7,64.6c0-1-3.1-0.3-3.1,0.6
              c0.6,6.4,14.1,10.1,18.9,11.7c13.4,4.8,27.3,8.1,41.3,11.4c21.5,5,43.1,9.3,64.8,13c0.6,0.2,2.4-0.2,2.4-0.9
              c0.7-9.9,1.5-19.6,2.2-29.5c0-0.4-0.2-0.6-0.6-0.6c-23.5-3.8-47-7-70.5-9.3c-6.6-0.7-57.6-8.4-58.5,4.2
              C16.8,66.1,19.7,65.6,19.7,64.6z"/>
          </g>
        </g>
        <g transform="translate(140.000000, 215.706320)" opacity="0.3">
          <g>
            <path fill="#FFFFFF" d="M151.3,54.8c-5.5-6-15.5-4.8-23-5.1c-16.2-0.7-32.6-1.2-49-1.7c-25.6-1-51.2-1.6-76.6-2.3
              c-1.5,0-3.1,1.9-1.1,2.1c23.6,0.7,47.2,1.4,71,2.1c16,0.5,31.9,1,47.9,1.5c7.9,0.3,22.1-1.5,28,4.6
              C149.2,56.8,152.2,55.8,151.3,54.8L151.3,54.8z"/>
          </g>
        </g>
        <g transform="translate(112.000000, 242.706320)" opacity="0.3">
          <g>
            <path fill="#FFFFFF" d="M115.9,73.5C78.2,64.4,40.4,55.2,2.7,46.1c-0.9-0.2-3.5,1.1-1.8,1.5C38.6,56.7,76.4,65.8,114.1,75
              C115,75.1,117.7,73.8,115.9,73.5L115.9,73.5z"/>
          </g>
        </g>
        <g transform="translate(88.000000, 214.706320)" opacity="0.3">
          <g>
            <path fill="#FFFFFF" d="M35.3,46.3c-8.8-0.9-32.8-2.7-34.7,9.3c-0.2,0.9,2.9,0.3,2.9-0.5c1.6-10.3,21.8-8.4,29.9-7.5
              C34.2,47.7,36.9,46.5,35.3,46.3L35.3,46.3z"/>
          </g>
        </g>
        <path fillRule="evenodd" clipRule="evenodd" fill="#000002" d="M239,300.5c1.1,17.8,16,31.3,33.7,29.3
          c15.6-1.9,28.6-14.8,28.2-31.1C300.3,258.7,239.9,261.6,239,300.5c0,0.4,3.1,0,3.1-0.7c0.6-19.3,18.2-35.6,38.1-27
          c10.5,4.4,17.8,15.2,17.8,26.9c0.2,19.3-18.5,35.4-38.1,27c-10.8-4.6-17.2-15.2-17.8-26.9C242.1,299.2,239,299.8,239,300.5z"/>
        <g transform="translate(242.000000, 227.706320)">
          <path fillRule="evenodd" clipRule="evenodd" fill="#141820" d="M1.9,71.9c0.2,14,11.6,25.2,25.1,25c13.8-0.2,24.6-11.7,24.4-25.7
            c-0.2-14-11.5-25.2-25.1-25C12.6,46.4,1.6,57.9,1.9,71.9z"/>
          <path fillRule="evenodd" clipRule="evenodd" fill="#000002" d="M0.6,72.3c2.2,33.9,52.8,32.2,52.1-1.5c-0.7-34.3-51-32-52.1,1.3
            c0,1.1,2.6,0.6,2.6-0.4c0.5-14.6,12.1-27.4,27.3-24.4c11.2,2.3,19.4,12.3,19.6,24c0.2,15.3-13.4,27.6-28.2,24
            c-11-2.7-17.8-12.5-18.5-23.8C3,70.8,0.5,71.4,0.6,72.3z"/>
        </g>
        <g transform="translate(260.000000, 247.706320)">
          <path fillRule="evenodd" clipRule="evenodd" fill="#10131D" d="M11.2,47.1L27.1,99l-6.6,0.2l-18.8-47C1.7,52,1.5,44.3,11.2,47.1z
            "/>
          <path fillRule="evenodd" clipRule="evenodd" fill="#000002" d="M9.7,47.4C14.9,64.7,20.1,82,25.5,99.3c0.8-0.4,1.5-0.7,2.3-1.1
            c-5.8,0.2-5.4,1.3-7.3-3.7c-2.5-6.1-4.8-12.3-7.3-18.4c-2.5-6.3-4.8-12.3-7.3-18.6c-1.5-3.9-6-11.8,4.6-9.7c1,0.2,3.7-1.1,1.9-1.5
            c-4.3-0.7-10.6-0.7-12,4.4c-0.8,3.3,1.9,7.2,3.1,10.1c5,12.9,10.2,25.7,15.3,38.6c0.2,0.4,0.6,0.4,1,0.4c2.1,0,4.4,0,6.6-0.2
            c0.4,0,2.5-0.4,2.3-1.1C23.4,81.3,18.2,64,12.8,46.7C12.6,45.6,9.5,46.5,9.7,47.4z"/>
        </g>
        <g transform="translate(269.000000, 298.706320)">
          <g transform="translate(0.833333, 0.000000)">
            <path fillRule="evenodd" clipRule="evenodd" fill="#10131D" d="M23.5,46.7L7.3,46.4l-5.5,5.5c0,0,0.2,7.5,3.7,8.7
              s18.3,3,21.6-2.5C30.6,52.8,23.5,46.7,23.5,46.7z"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#000002" d="M24.6,46.2c-3.3,0-6.8-0.2-10.1-0.2c-2.2,0-5.1-0.7-7.2,0
              c-2.2,0.7-5.3,3.9-6.4,5.7c-1.1,2-0.2,5.3,0.9,7.3c3.1,5.3,15.6,4.3,20.7,2.8c8.4-2.3,8.6-10.1,2.4-15.5c-0.5-0.5-3.3,0.5-2.9,1.1
              c2.9,2.7,6.2,7.5,3.7,11.2c-2.9,4.4-13,3.2-17.4,2c-2.9-0.9-5.9-5.5-4.8-8.7c0.5-1.6,3.9-4.1,5.3-5.5c-0.7,0.2-1.7,0.5-2.4,0.7
              c5.3,0.2,10.8,0.2,16.1,0.4C23.1,47.4,26,46.2,24.6,46.2z"/>
          </g>
          <g transform="translate(0.000000, 5.333333)">
            <path fillRule="evenodd" clipRule="evenodd" fill="#000002" d="M27.2,48.8L2.7,46.5c0,0-4.8,11,15.6,11.4
              C18.2,57.9,33.7,55.6,27.2,48.8z"/>
            <path fillRule="evenodd" clipRule="evenodd" fill="#000002" d="M27.8,47.8c-8.2-0.7-16.5-1.6-24.6-2.3c-0.7,0-1.6,0.2-2,0.9
              c-3.1,7.3,5.1,11.4,11.4,12.3c4.6,0.7,8.8,0.2,13-1.6c4-1.8,6.4-5,3.1-8.7c-0.9-1.1-3.9,0.2-2.8,1.4c3.5,4.1-1.7,5.9-5.3,6.8
              s-7.5,0.4-11.2-1c-3.7-1.2-7-4.6-5.3-8.7c-0.7,0.4-1.3,0.5-2,0.9c8.2,0.7,16.5,1.6,24.6,2.3C28.3,50.1,29.8,48,27.8,47.8z"/>
          </g>
        </g>
        <path fill="#000002" d="M114,279.1C98,239.5,82,199.7,66,160c-0.4,0.9-0.7,1.9-1.1,2.8c26.7-0.9,53.2-1.9,79.8-2.8
          c-0.9-1.3-1.8-2.8-2.8-4.1c-10.7,40.6-21.2,81.3-31.8,121.9c-0.6,2.2,3.1,6,3.9,3.2c10.7-40.6,21.2-81.3,31.8-121.9
          c0.6-1.7-0.7-4.1-2.8-4.1c-26.7,0.9-53.2,1.9-79.8,2.8c-1.3,0-1.5,1.9-1.1,2.8c16,39.6,32,79.5,48,119.1
          C111.3,282.3,115.5,282.3,114,279.1z"/>
        <g transform="translate(30.000000, 98.706320)">
          <path fillRule="evenodd" clipRule="evenodd" fill="#10131D" d="M131.5,56.8L3.8,61.4c0,0-2.6-5.1-0.2-7.8s125.3-6.8,125.3-6.8
            S131.3,52.5,131.5,56.8z"/>
          <path fillRule="evenodd" clipRule="evenodd" fill="#000002" d="M131.7,55.7c-36.9,1.3-73.9,2.7-110.8,4C16.8,59.9,8,62,4.3,60.3
            c0,0,0.6,0.6,0.2-1.3c-1.5-7.3-0.9-3.9,1.7-5s7.2-0.8,9.7-1c22.1-1.7,44.3-2.5,66.5-3.4c11.6-0.4,23.2-1,34.7-1.3
            c2.9-0.2,6.2-0.8,9.2-0.4c0.6,0,1.7-0.4,2,0c-1.3-0.6,1.3,7.4,1.7,9.3c0.2,1.3,3.3,0.6,3.1-0.8c-0.4-3.4-1.5-6.7-2.8-9.9
            c-0.2-0.6-0.7-0.8-1.5-0.8c-24.3,0.8-48.5,1.7-72.8,2.8c-14.3,0.6-28.5,1.3-42.8,2.5C6.6,51.6-2.8,51.4,2,61.6
            c0.2,0.6,0.7,0.8,1.5,0.8c42.6-1.5,85.3-3,127.9-4.6C133.2,58,133.7,55.7,131.7,55.7z"/>
        </g>
        <g transform="translate(20.000000, 105.706320)">
          <polygon fillRule="evenodd" clipRule="evenodd" fill="#BF3126" points="14.7,46.2 13.9,57.5 2.6,54.9 3.5,46.2   "/>
          <path fillRule="evenodd" clipRule="evenodd" fill="#911A1C" d="M12.1,46.9c-0.3,3.9-0.7,7.5-0.9,11.4c1.6-0.5,3.1-1,4.5-1.6
            c-3.5-0.9-7.1-2.1-10.5-2.5c0,0-0.5,0-0.5-0.2c0.7,0.7,0.3,0,0.5-0.2c0.3-0.7,0.2-1.9,0.3-2.6c0.2-1.9,0.3-3.7,0.5-5.6
            c-1.4,0.5-2.8,1-4.1,1.8c3.8,0,7.4,0,11.2,0c1,0,2.8-0.2,3.5-0.9c0.7-0.5,0.9-1-0.3-1c-3.8,0-7.4,0-11.2,0c-0.9,0-4,0.3-4.1,1.8
            C0.6,50.2,0.4,53,0,56c0.2,0.2,0.2,0.2,0.5,0.3c3.8,0.9,7.4,1.8,11.2,2.6c1,0.2,4.5-0.3,4.5-1.6c0.3-3.8,0.7-7.5,0.9-11.4
            C17.3,44.9,12.3,45.5,12.1,46.9z"/>
        </g>

        {interactive ? (

          <g id="parts">
            <g id="stuur" transform="translate(283.724395, 0.000000)">
              <Link to="/stuur">
                <text className="visually-hidden">Het stuur</text>
                <circle className="part pulsating" fillRule="evenodd" clipRule="evenodd" fill="#009CCA" stroke="#009CCA" strokeWidth="10" strokeOpacity="0.2" cx="75" cy="44" r="16"/>
                <path className="icon pass" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#FFFFFF" d="M81.7,40.4
                  c-0.2,0-0.3,0.1-0.5,0.2c-2.6,2.7-4.6,4.9-7.1,7.5l-2.9-2.5c-0.2-0.2-0.5-0.2-0.8-0.1c-0.3,0.1-0.5,0.3-0.5,0.6s0.1,0.6,0.3,0.8
                  l3.4,3c0.3,0.3,0.8,0.2,1.1-0.1c2.8-2.9,4.8-5.2,7.6-8c0.2-0.2,0.3-0.6,0.2-0.9C82.4,40.6,82,40.4,81.7,40.4L81.7,40.4z"/>
                <path className="icon fail" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#FFFFFF" d="M76.2,44.2l4.6-4.6
                  c0.2-0.2,0.2-0.6,0-0.8s-0.6-0.2-0.8,0l-4.6,4.6l-4.6-4.6c-0.2-0.2-0.6-0.2-0.8,0c-0.2,0.2-0.2,0.6,0,0.8l4.6,4.6L70,48.7
                  c-0.2,0.2-0.2,0.6,0,0.8c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.2l4.6-4.6l4.6,4.6c0.1,0.1,0.2,0.2,0.4,0.2s0.3-0.1,0.4-0.2
                  c0.2-0.2,0.2-0.6,0-0.8L76.2,44.2z"/>
              </Link>
            </g>
            <g id="koplamp" transform="translate(361.191806, 101.730104)">
              <Link to="/koplamp" >
                <text className="visually-hidden">De koplamp</text>
                <circle className="part pulsating" fillRule="evenodd" clipRule="evenodd" fill="#009CCA" stroke="#009CCA" strokeWidth="10" strokeOpacity="0.2" cx="75" cy="44" r="16"/>
                <path className="icon pass" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#FFFFFF" d="M81.7,40.4
                  c-0.2,0-0.3,0.1-0.5,0.2c-2.6,2.7-4.6,4.9-7.1,7.5l-2.9-2.5c-0.2-0.2-0.5-0.2-0.8-0.1c-0.3,0.1-0.5,0.3-0.5,0.6s0.1,0.6,0.3,0.8
                  l3.4,3c0.3,0.3,0.8,0.2,1.1-0.1c2.8-2.9,4.8-5.2,7.6-8c0.2-0.2,0.3-0.6,0.2-0.9C82.4,40.6,82,40.4,81.7,40.4L81.7,40.4z"/>
                <path className="icon fail" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#FFFFFF" d="M76.2,44.2l4.6-4.6
                  c0.2-0.2,0.2-0.6,0-0.8s-0.6-0.2-0.8,0l-4.6,4.6l-4.6-4.6c-0.2-0.2-0.6-0.2-0.8,0c-0.2,0.2-0.2,0.6,0,0.8l4.6,4.6L70,48.7
                  c-0.2,0.2-0.2,0.6,0,0.8c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.2l4.6-4.6l4.6,4.6c0.1,0.1,0.2,0.2,0.4,0.2s0.3-0.1,0.4-0.2
                  c0.2-0.2,0.2-0.6,0-0.8L76.2,44.2z"/>
              </Link>
            </g>
            <g id="wielen" transform="translate(469.646182, 217.993080)">
              <Link to="/wielen" >
                <text className="visually-hidden">De wielen</text>
                <circle className="part pulsating" fillRule="evenodd" clipRule="evenodd" fill="#009CCA" stroke="#009CCA" strokeWidth="10" strokeOpacity="0.2" cx="75" cy="44" r="16"/>
                <path className="icon pass" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#FFFFFF" d="M81.7,40.4
                  c-0.2,0-0.3,0.1-0.5,0.2c-2.6,2.7-4.6,4.9-7.1,7.5l-2.9-2.5c-0.2-0.2-0.5-0.2-0.8-0.1c-0.3,0.1-0.5,0.3-0.5,0.6s0.1,0.6,0.3,0.8
                  l3.4,3c0.3,0.3,0.8,0.2,1.1-0.1c2.8-2.9,4.8-5.2,7.6-8c0.2-0.2,0.3-0.6,0.2-0.9C82.4,40.6,82,40.4,81.7,40.4L81.7,40.4z"/>
                <path className="icon fail" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#FFFFFF" d="M76.2,44.2l4.6-4.6
                  c0.2-0.2,0.2-0.6,0-0.8s-0.6-0.2-0.8,0l-4.6,4.6l-4.6-4.6c-0.2-0.2-0.6-0.2-0.8,0c-0.2,0.2-0.2,0.6,0,0.8l4.6,4.6L70,48.7
                  c-0.2,0.2-0.2,0.6,0,0.8c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.2l4.6-4.6l4.6,4.6c0.1,0.1,0.2,0.2,0.4,0.2s0.3-0.1,0.4-0.2
                  c0.2-0.2,0.2-0.6,0-0.8L76.2,44.2z"/>
              </Link>
            </g>
            <g id="zadel" transform="translate(128.789572, 40.692042)">
              <Link to="/zadel" >
                <text className="visually-hidden">Het zadel</text>
                <circle className="part pulsating" fillRule="evenodd" clipRule="evenodd" fill="#009CCA" stroke="#009CCA" strokeWidth="10" strokeOpacity="0.2" cx="75" cy="44" r="16"/>
                <path className="icon pass" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#FFFFFF" d="M81.7,40.4
                  c-0.2,0-0.3,0.1-0.5,0.2c-2.6,2.7-4.6,4.9-7.1,7.5l-2.9-2.5c-0.2-0.2-0.5-0.2-0.8-0.1c-0.3,0.1-0.5,0.3-0.5,0.6s0.1,0.6,0.3,0.8
                  l3.4,3c0.3,0.3,0.8,0.2,1.1-0.1c2.8-2.9,4.8-5.2,7.6-8c0.2-0.2,0.3-0.6,0.2-0.9C82.4,40.6,82,40.4,81.7,40.4L81.7,40.4z"/>
                <path className="icon fail" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#FFFFFF" d="M76.2,44.2l4.6-4.6
                  c0.2-0.2,0.2-0.6,0-0.8s-0.6-0.2-0.8,0l-4.6,4.6l-4.6-4.6c-0.2-0.2-0.6-0.2-0.8,0c-0.2,0.2-0.2,0.6,0,0.8l4.6,4.6L70,48.7
                  c-0.2,0.2-0.2,0.6,0,0.8c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.2l4.6-4.6l4.6,4.6c0.1,0.1,0.2,0.2,0.4,0.2s0.3-0.1,0.4-0.2
                  c0.2-0.2,0.2-0.6,0-0.8L76.2,44.2z"/>
              </Link>
            </g>
            <g id="achterkant" transform="translate(0.000000, 140.484429)">
              <Link to="/achterkant" >
                <text className="visually-hidden">De achterkant</text>
                <circle className="part pulsating" fillRule="evenodd" clipRule="evenodd" fill="#009CCA" stroke="#009CCA" strokeWidth="10" strokeOpacity="0.2" cx="45" cy="54" r="16"/>
                <path className="icon pass" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#FFFFFF" d="M51.7,50.4
                  c-0.2,0-0.3,0.1-0.5,0.2c-2.6,2.7-4.6,4.9-7.1,7.5l-2.9-2.5c-0.2-0.2-0.5-0.2-0.8-0.1c-0.3,0.1-0.5,0.3-0.5,0.6s0.1,0.6,0.3,0.8
                  l3.4,3c0.3,0.3,0.8,0.2,1.1-0.1c2.8-2.9,4.8-5.2,7.6-8c0.2-0.2,0.3-0.6,0.2-0.9S52,50.4,51.7,50.4L51.7,50.4z"/>
                <path className="icon fail" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#FFFFFF" d="M46.2,54.2l4.6-4.6
                  c0.2-0.2,0.2-0.6,0-0.8s-0.6-0.2-0.8,0l-4.6,4.6l-4.6-4.6c-0.2-0.2-0.6-0.2-0.8,0s-0.2,0.6,0,0.8l4.6,4.6L40,58.7
                  c-0.2,0.2-0.2,0.6,0,0.8c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.2l4.6-4.6l4.6,4.6c0.1,0.1,0.2,0.2,0.4,0.2
                  c0.1,0,0.3-0.1,0.4-0.2c0.2-0.2,0.2-0.6,0-0.8L46.2,54.2z"/>
              </Link>
            </g>
            <g id="trappers-en-ketting" transform="translate(157.839851, 229.619377)">
              <Link to="/trappers-en-ketting" >
                <text className="visually-hidden">Trappers en ketting</text>
                <circle className="part pulsating" fillRule="evenodd" clipRule="evenodd" fill="#009CCA" stroke="#009CCA" strokeWidth="10" strokeOpacity="0.2" cx="35" cy="44" r="16"/>
                <path className="icon pass" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#FFFFFF" d="M41.7,40.4
                  c-0.2,0-0.3,0.1-0.5,0.2c-2.6,2.7-4.6,4.9-7.1,7.5l-2.9-2.5c-0.2-0.2-0.5-0.2-0.8-0.1c-0.3,0.1-0.5,0.3-0.5,0.6s0.1,0.6,0.3,0.8
                  l3.4,3c0.3,0.3,0.8,0.2,1.1-0.1c2.8-2.9,4.8-5.2,7.6-8c0.2-0.2,0.3-0.6,0.2-0.9S42,40.4,41.7,40.4L41.7,40.4z"/>
                <path className="icon fail" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#FFFFFF" d="M36.2,44.2l4.6-4.6
                  c0.2-0.2,0.2-0.6,0-0.8s-0.6-0.2-0.8,0l-4.6,4.6l-4.6-4.6c-0.2-0.2-0.6-0.2-0.8,0c-0.2,0.2-0.2,0.6,0,0.8l4.6,4.6L30,48.7
                  c-0.2,0.2-0.2,0.6,0,0.8c0.1,0.1,0.2,0.2,0.4,0.2s0.3-0.1,0.4-0.2l4.6-4.6l4.6,4.6c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.2
                  c0.2-0.2,0.2-0.6,0-0.8L36.2,44.2z"/>
              </Link>
            </g>
          </g>

        ) : (

          <g id="parts">
            <g id="stuur" transform="translate(283.724395, 0.000000)">
              <circle className="part" fillRule="evenodd" clipRule="evenodd" fill="#009CCA" stroke="#009CCA" strokeWidth="10" strokeOpacity="0.2" cx="75" cy="44" r="16"/>
              <path className="icon pass" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#FFFFFF" d="M81.7,40.4
                c-0.2,0-0.3,0.1-0.5,0.2c-2.6,2.7-4.6,4.9-7.1,7.5l-2.9-2.5c-0.2-0.2-0.5-0.2-0.8-0.1c-0.3,0.1-0.5,0.3-0.5,0.6s0.1,0.6,0.3,0.8
                l3.4,3c0.3,0.3,0.8,0.2,1.1-0.1c2.8-2.9,4.8-5.2,7.6-8c0.2-0.2,0.3-0.6,0.2-0.9C82.4,40.6,82,40.4,81.7,40.4L81.7,40.4z"/>
              <path className="icon fail" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#FFFFFF" d="M76.2,44.2l4.6-4.6
                c0.2-0.2,0.2-0.6,0-0.8s-0.6-0.2-0.8,0l-4.6,4.6l-4.6-4.6c-0.2-0.2-0.6-0.2-0.8,0c-0.2,0.2-0.2,0.6,0,0.8l4.6,4.6L70,48.7
                c-0.2,0.2-0.2,0.6,0,0.8c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.2l4.6-4.6l4.6,4.6c0.1,0.1,0.2,0.2,0.4,0.2s0.3-0.1,0.4-0.2
                c0.2-0.2,0.2-0.6,0-0.8L76.2,44.2z"/>
            </g>
            <g id="koplamp" transform="translate(361.191806, 101.730104)">
              <circle className="part" fillRule="evenodd" clipRule="evenodd" fill="#009CCA" stroke="#009CCA" strokeWidth="10" strokeOpacity="0.2" cx="75" cy="44" r="16"/>
              <path className="icon pass" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#FFFFFF" d="M81.7,40.4
                c-0.2,0-0.3,0.1-0.5,0.2c-2.6,2.7-4.6,4.9-7.1,7.5l-2.9-2.5c-0.2-0.2-0.5-0.2-0.8-0.1c-0.3,0.1-0.5,0.3-0.5,0.6s0.1,0.6,0.3,0.8
                l3.4,3c0.3,0.3,0.8,0.2,1.1-0.1c2.8-2.9,4.8-5.2,7.6-8c0.2-0.2,0.3-0.6,0.2-0.9C82.4,40.6,82,40.4,81.7,40.4L81.7,40.4z"/>
              <path className="icon fail" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#FFFFFF" d="M76.2,44.2l4.6-4.6
                c0.2-0.2,0.2-0.6,0-0.8s-0.6-0.2-0.8,0l-4.6,4.6l-4.6-4.6c-0.2-0.2-0.6-0.2-0.8,0c-0.2,0.2-0.2,0.6,0,0.8l4.6,4.6L70,48.7
                c-0.2,0.2-0.2,0.6,0,0.8c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.2l4.6-4.6l4.6,4.6c0.1,0.1,0.2,0.2,0.4,0.2s0.3-0.1,0.4-0.2
                c0.2-0.2,0.2-0.6,0-0.8L76.2,44.2z"/>
            </g>
            <g id="wielen" transform="translate(469.646182, 217.993080)">
              <circle className="part" fillRule="evenodd" clipRule="evenodd" fill="#009CCA" stroke="#009CCA" strokeWidth="10" strokeOpacity="0.2" cx="75" cy="44" r="16"/>
              <path className="icon pass" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#FFFFFF" d="M81.7,40.4
                c-0.2,0-0.3,0.1-0.5,0.2c-2.6,2.7-4.6,4.9-7.1,7.5l-2.9-2.5c-0.2-0.2-0.5-0.2-0.8-0.1c-0.3,0.1-0.5,0.3-0.5,0.6s0.1,0.6,0.3,0.8
                l3.4,3c0.3,0.3,0.8,0.2,1.1-0.1c2.8-2.9,4.8-5.2,7.6-8c0.2-0.2,0.3-0.6,0.2-0.9C82.4,40.6,82,40.4,81.7,40.4L81.7,40.4z"/>
              <path className="icon fail" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#FFFFFF" d="M76.2,44.2l4.6-4.6
                c0.2-0.2,0.2-0.6,0-0.8s-0.6-0.2-0.8,0l-4.6,4.6l-4.6-4.6c-0.2-0.2-0.6-0.2-0.8,0c-0.2,0.2-0.2,0.6,0,0.8l4.6,4.6L70,48.7
                c-0.2,0.2-0.2,0.6,0,0.8c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.2l4.6-4.6l4.6,4.6c0.1,0.1,0.2,0.2,0.4,0.2s0.3-0.1,0.4-0.2
                c0.2-0.2,0.2-0.6,0-0.8L76.2,44.2z"/>
            </g>
            <g id="zadel" transform="translate(128.789572, 40.692042)">
              <circle className="part" fillRule="evenodd" clipRule="evenodd" fill="#009CCA" stroke="#009CCA" strokeWidth="10" strokeOpacity="0.2" cx="75" cy="44" r="16"/>
              <path className="icon pass" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#FFFFFF" d="M81.7,40.4
                c-0.2,0-0.3,0.1-0.5,0.2c-2.6,2.7-4.6,4.9-7.1,7.5l-2.9-2.5c-0.2-0.2-0.5-0.2-0.8-0.1c-0.3,0.1-0.5,0.3-0.5,0.6s0.1,0.6,0.3,0.8
                l3.4,3c0.3,0.3,0.8,0.2,1.1-0.1c2.8-2.9,4.8-5.2,7.6-8c0.2-0.2,0.3-0.6,0.2-0.9C82.4,40.6,82,40.4,81.7,40.4L81.7,40.4z"/>
              <path className="icon fail" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#FFFFFF" d="M76.2,44.2l4.6-4.6
                c0.2-0.2,0.2-0.6,0-0.8s-0.6-0.2-0.8,0l-4.6,4.6l-4.6-4.6c-0.2-0.2-0.6-0.2-0.8,0c-0.2,0.2-0.2,0.6,0,0.8l4.6,4.6L70,48.7
                c-0.2,0.2-0.2,0.6,0,0.8c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.2l4.6-4.6l4.6,4.6c0.1,0.1,0.2,0.2,0.4,0.2s0.3-0.1,0.4-0.2
                c0.2-0.2,0.2-0.6,0-0.8L76.2,44.2z"/>
            </g>
            <g id="achterkant" transform="translate(0.000000, 140.484429)">
              <circle className="part" fillRule="evenodd" clipRule="evenodd" fill="#009CCA" stroke="#009CCA" strokeWidth="10" strokeOpacity="0.2" cx="45" cy="54" r="16"/>
              <path className="icon pass" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#FFFFFF" d="M51.7,50.4
                c-0.2,0-0.3,0.1-0.5,0.2c-2.6,2.7-4.6,4.9-7.1,7.5l-2.9-2.5c-0.2-0.2-0.5-0.2-0.8-0.1c-0.3,0.1-0.5,0.3-0.5,0.6s0.1,0.6,0.3,0.8
                l3.4,3c0.3,0.3,0.8,0.2,1.1-0.1c2.8-2.9,4.8-5.2,7.6-8c0.2-0.2,0.3-0.6,0.2-0.9S52,50.4,51.7,50.4L51.7,50.4z"/>
              <path className="icon fail" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#FFFFFF" d="M46.2,54.2l4.6-4.6
                c0.2-0.2,0.2-0.6,0-0.8s-0.6-0.2-0.8,0l-4.6,4.6l-4.6-4.6c-0.2-0.2-0.6-0.2-0.8,0s-0.2,0.6,0,0.8l4.6,4.6L40,58.7
                c-0.2,0.2-0.2,0.6,0,0.8c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.2l4.6-4.6l4.6,4.6c0.1,0.1,0.2,0.2,0.4,0.2
                c0.1,0,0.3-0.1,0.4-0.2c0.2-0.2,0.2-0.6,0-0.8L46.2,54.2z"/>
            </g>
            <g id="trappers-en-ketting" transform="translate(157.839851, 229.619377)">
              <circle className="part" fillRule="evenodd" clipRule="evenodd" fill="#009CCA" stroke="#009CCA" strokeWidth="10" strokeOpacity="0.2" cx="35" cy="44" r="16"/>
              <path className="icon pass" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#FFFFFF" d="M41.7,40.4
                c-0.2,0-0.3,0.1-0.5,0.2c-2.6,2.7-4.6,4.9-7.1,7.5l-2.9-2.5c-0.2-0.2-0.5-0.2-0.8-0.1c-0.3,0.1-0.5,0.3-0.5,0.6s0.1,0.6,0.3,0.8
                l3.4,3c0.3,0.3,0.8,0.2,1.1-0.1c2.8-2.9,4.8-5.2,7.6-8c0.2-0.2,0.3-0.6,0.2-0.9S42,40.4,41.7,40.4L41.7,40.4z"/>
              <path className="icon fail" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" stroke="#FFFFFF" d="M36.2,44.2l4.6-4.6
                c0.2-0.2,0.2-0.6,0-0.8s-0.6-0.2-0.8,0l-4.6,4.6l-4.6-4.6c-0.2-0.2-0.6-0.2-0.8,0c-0.2,0.2-0.2,0.6,0,0.8l4.6,4.6L30,48.7
                c-0.2,0.2-0.2,0.6,0,0.8c0.1,0.1,0.2,0.2,0.4,0.2s0.3-0.1,0.4-0.2l4.6-4.6l4.6,4.6c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.2
                c0.2-0.2,0.2-0.6,0-0.8L36.2,44.2z"/>
            </g>
          </g>
        )}
        </svg>
      </div>
    </StyledStatus>
  );
}


import React from 'react';
import styled from 'styled-components';
import { Breakpoint } from '../../styles/base/variables/layout';
import { ReactComponent as IconFacebook } from '../../assets/images/facebook.svg';
import { ReactComponent as IconWhatsapp } from '../../assets/images/whatsapp.svg';

const SendResultList = styled.ul`

  ${Breakpoint.greaterThan('small')`
  `}

  display: flex;
  list-style: none;
  margin-right: -5px;
  margin-bottom: -5px;

  li {
    margin-right: 5px;
    margin-bottom: 5px;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: ${props => props.theme.color.green};
    color: ${props => props.theme.color.white};
  }
`;

export default function SendResultLinks({ link, text }) {
  const facebookSendLink = 'https://www.facebook.com/share.php?u=' + link;
  const whatsappSendLink = 'whatsapp://send?text='+ text +  ' ' + link;

  return (
    <SendResultList>
      <li>
        <a href={facebookSendLink}>
          <span className="visually-hidden">Deel het resultaat op Facebook</span>
          <span className="icon"><IconFacebook/></span>
        </a>
      </li>
      <li>
        <a href={whatsappSendLink}>
          <span className="visually-hidden">Deel het resultaat op Whatsapp</span>
          <span className="icon"><IconWhatsapp/></span>
        </a>
      </li>
    </SendResultList>
  );
}

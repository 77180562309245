import React from 'react';
import styled from 'styled-components';
import { ReactComponent as IconCheck } from '../../assets/images/check.svg';
import { ReactComponent as IconCross } from '../../assets/images/cross.svg';

const StyledForm = styled.form`
  legend {
    color: ${props => props.theme.color.green};
    padding-top: 10px;
  }

  .body {
    margin-top: 20px;
  }

  .options {
    margin-top: 40px;
    margin-right: -20px;
    margin-bottom: -20px;
    > label {
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }

  label {
    color: ${props => props.theme.color.white};
    &[for="yes"] {
      background-color: ${props => props.theme.color.green};
      border-color: ${props => props.theme.color.green};
    }
    &[for="no"] {
      background-color: ${props => props.theme.color.orange};
      border-color: ${props => props.theme.color.orange};
    }
  }
`;

export default function QuestionForm(props) {
  function isChecked(value) {
    if (value === props.result) {
      return true;
    }

    return false;
  }

  return (
    <StyledForm>
      <fieldset>
        <legend><span className="h2">{props.question}</span></legend>
        <div className="body">{props.explanation}</div>
        <div className="options">
          <input
            type="radio"
            name="pass"
            id="yes"
            value="yes"
            onChange={props.handleOptionChange}
            checked={isChecked('yes')}
          />
          <label htmlFor="yes">
            <span className="text">Ja</span>
            <span className="icon" role="presentation"><IconCheck/></span>
          </label>
          <input
            type="radio"
            name="pass"
            id="no"
            value="no"
            onChange={props.handleOptionChange}
            checked={isChecked('no')}
          />
          <label htmlFor="no">
            <span className="text">Nee</span>
            <span className="icon" role="presentation"><IconCross/></span>
          </label>
        </div>
      </fieldset>
    </StyledForm>
  );
}

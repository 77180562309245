import styled from 'styled-components';

export const ButtonOuterGreen = styled.div`
  button,
  .button {
    background-color: ${props => props.theme.color.green};
    color: ${props => props.theme.color.white};
    border-color: ${props => props.theme.color.green};
  }
`;

export const ButtonOuterGreenGhost = styled.div`
  button,
  .button {
    color: ${props => props.theme.color.green};
    border-color: ${props => props.theme.color.green};
  }
`;

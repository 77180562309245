import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LightBulb } from '../../assets/images/light-bulb.svg';

const StyledFact = styled.div`
  svg {
    fill: ${props => props.theme.color.blue};
  }
`;

const StyledFactHeading = styled.h3`
  display: flex;  
  align-items: center;
  font-size: inherit;

  svg {
    margin-right: 10px;
  }
`;

const StyledFactText = styled.p`
  font-style: italic;
  margin-top: 10px;
`;

export default function Fact(props) {
  if (props.fact !== undefined) {
    return (
      <StyledFact className="fact">
        <StyledFactHeading>
        <LightBulb/>Wist je dat...</StyledFactHeading>
        <StyledFactText>{props.fact}</StyledFactText>
      </StyledFact>
    );
  }

  return null;
}

export const SET_RESULT = 'SET_RESULT';
export const DELETE_RESULT = 'DELETE_RESULT';
export const DELETE_RESULTS = 'DELETE_RESULTS';
export const SET_REMOTE = 'SET_REMOTE';

export const setResult = result => ({
  type: SET_RESULT,
  result: result,
});

export const deleteResult = slugAndId => ({
  type: DELETE_RESULT,
  slugAndId: slugAndId,
});

export const setRemote = remote => ({
  type: SET_REMOTE,
  remote: remote,
});

export const deleteResults = () => ({
  type: DELETE_RESULTS,
});
